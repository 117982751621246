import {validateFamily0} from "./family0";
import {validateFamily1} from "./family1";
import { validateFamily2 } from "./family2";
import { validateFamily3 } from "./family3";
import { validateFamily4 } from "./family4";
import { validateFamily5 } from "./family5";
import { validateFamily6 } from "./family6";
import { validateFamily7 } from "./family7";
import { validateFamily8 } from "./family8";

export const increaseData = (obj, chave, erro, linha, id, legenda) => {
  if(!obj) obj = { total: 0, legendas: {} };
  if(!obj[chave]) obj[chave] = { id_venda: id , linhas: new Set() }
  if(!obj[chave][erro])obj[chave][erro] = 0;
  obj[chave][erro] = obj[chave][erro] + 1;
  obj[chave].linhas.add(linha);
  obj.total = obj.total + 1;
  obj.legendas[erro] = legenda || `Adicionar descrição ao erro ${erro}`;
}

/**
 * Padroniza o nome das props das linhas para o restante do código
 * 
 * @param {any[]} lines 
 * @returns any[]
 */
const standardProps = (lines) => {
  return lines.map(l => {
    if (l.cdigodaempresa) l.codigodaempresa = l.cdigodaempresa;
    if (l.cdigodaobra) l.codigodaobra = l.cdigodaobra;
    if (l.alienaofiduciria) l.alienacaofiduciaria = l.alienaofiduciria;
    if (l.statuscobrana) l.statuscobranca = l.statuscobrana;
    if (l.valorfuturodasparcelas) l.valornominaldasparcelas = l.valorfuturodasparcelas;
    if (l.valorparaantecipao) l.valorparaantecipacao = l.valorparaantecipao;
    return l;
  });
}

export function validateFamilies(lines) {
  let obj = { total: 0, legendas: {} };

  lines = standardProps(lines);

  const family0 = validateFamily0(lines);
  let family0_final = family0;

  // CPF/CNPJ pode chegar anonimizado desde a base
  if (family0.total > lines.length / 2) {
    obj.legendas = { Info_001: 'CPF/CNPJ provavelmente anonimizado por toda base'}
    family0_final = { total: 0, legendas: {} }
  }
  const family1 = validateFamily1(lines);
  const family2 = validateFamily2(lines);
  const family3 = validateFamily3(lines);
  const family4 = validateFamily4(lines);
  const family5 = validateFamily5(lines);
  const family6 = validateFamily6(lines);
  const family7 = validateFamily7(lines);
  const family8 = validateFamily8(lines);
  // faz o merge correto das informações
  const families = [family0_final, family1, family2, family3, family4, family5, family6, family7];
  families.forEach(f => {
    Object.keys(f).forEach(k => {
      if (k === 'legendas') Object.assign(obj.legendas, f[k]);
      else if (k === 'total') obj.total += f[k];
      else if (!obj[k]) obj[k] = f[k];
      else {
        obj[k].linhas = new Set([...obj[k].linhas, ...f[k].linhas]);
        Object.keys(f[k]).forEach(v => {
          if (v === 'id_venda' || v === 'linhas') return;
          if (!obj[k][v]) obj[k][v] = f[k][v];
          else obj[k][v] += f[k][v];
        })
      }
    })
  })
  const mod = {
    erro_cabecalho: false,
    total_erros: obj.total,
    total_idvendas: 0,
    legendas: Object.keys(obj.legendas).map(leg => ({ titulo: leg, descricao: obj.legendas[leg] })),
    colunas: [{ field: 'Erro_518', headerName: 'Erro_518', flex: 1 }],
    erros: []
  }
  const set_colunas = new Set();
  const set_idvendas = new Set();
  Object.entries(obj).forEach(([k,v]) => {
    if (k.substring(0,3) === 'idv') {
      const { linhas, id_venda, ...cols } = v;
      mod.erros.push({
        id_venda,
        linhas: Array.from(linhas).reduce((p,c) => `${p}${c},`),
        ...cols
      });
      set_idvendas.add(id_venda);
      Object.keys(cols).forEach((k) => set_colunas.add(k));
    }
  })
  mod.colunas = Array.from(set_colunas).map(c => ({ field: c, headerName: c, flex: 1 }));
  mod.total_idvendas = set_idvendas.size;
  return mod;
}
