import { useEffect, useState } from 'react';

import { Container, ContainerWrapper, FileButtons, Main, Aside } from './styles';
import { ContentBox, TitleBox, TextBox, DownloadButton, ValidateButton, UploadButton, ValidationsErrors } from './stylesMui';
import { Box, Typography } from '@mui/material';
import HeaderTitle from '../../components/HeaderTitle';
import SubHeader from '../../components/SubHeader';
import FileUpload from '../../components/FileUpload';
import AccordionMui from './components/Accordion';
import { api } from '../../api';
import ValidationsDetails from './components/ValidationsDetails';
import { v4 } from 'uuid';
import { validaCabecalho } from "../../validations/family0";
import { validateFamilies } from '../../validations';
import Cookies from 'universal-cookie';
import { DataTableEnviados } from './components/DataTableEnviados';
import { toast } from 'react-toastify';
import { useAppSelector } from '../../store';

const cookies = new Cookies();

interface iState {
  portfolioId: number;
  portfolio: any;
  erros: any[];
  tipoValido:boolean;
  validado: boolean;
  arquivos: any[];
  enviados: any[];
  colunas: any[];
  legendas: any[];
  validaAdmin: boolean;
}

const CarteiraPre = (props: any) => {
  const partner = useAppSelector(state => state.partner);
  const user = useAppSelector(state => state.user);
  const admin = localStorage.getItem("@user:admin");
  const partnerSelected = partner.find((value) => value.selected);
  const partnerStorage = localStorage.getItem("@user:partner")
  const [state, setState] = useState<iState>({
    portfolioId: 0,
    portfolio: null,
    erros: [],
    colunas: [],
    legendas: [],
    tipoValido: false,
    validado: false,
    validaAdmin: false,
    arquivos: [{}],
    enviados: [],
  })
  const qtdErros = state?.erros[0]?.erros.length
  const qtdTotalErros = state?.erros[0]?.total_erros

  useEffect(() => {
    if(!partnerSelected && Number(partnerStorage) === 0) toast.warning("Não há parceiros selecionados em sua carteira")
    getFiles();
  }, []);

  const getFiles = () => {
    const partner_id = partnerSelected?.id || Number(partnerStorage) || 0;
    const toastId = toast.loading("Carregando Carteiras Analisadas")
    return api.get('/backend/partners/' + partner_id + '/portfolios').then((response) => {
      var json = response.data;
      let enviados = json.data.portfolios.filter(x => x.type === 2); // pre venda
      const pid = enviados[0].id;
      enviados = enviados[0].files.sort((a,b) => a.id-b.id);
      setState({ ...state, enviados, portfolioId: pid });
    }).catch((error) => {
      toast.error("Não Foi Possível Carregar Carteiras Analisadas")
    }).finally(() => {
      toast.dismiss(toastId);
    });
  }

  const handleOnChange = (event: any) => {
    const files = event.target.files
    const arquivos: any[] = []
    setState({...state, arquivos: []});

    if (!files) return;
    const all_selected = Object.keys(files);
    all_selected.forEach((key) => {
      const selected = files[key];
      if (selected.type === 'text/csv') arquivos.push(selected);
    });
    if (all_selected.length === arquivos.length) setState({...state, arquivos: arquivos, erros: [], validado: false, tipoValido: true});
    else setState({
      ...state,
      arquivos: [],
      erros: [{...state.erros, message: 'Arquivo não é do tipo CSV'}],
      validado: false,
      tipoValido: false
    });
  }

  const csvFileToArray = (text) => {
    const csvHeader = text.slice(0, text.indexOf("\n")).split(";");
    const csvRows = text.slice(text.indexOf("\n") + 1).split("\n");

    const array = csvRows.map(i => {
      const row = i.trim();
      if (!row || row === '') return {empty: true}
      const values = i.split(";");
      const obj = csvHeader.reduce((object, header, index) => {
        const parsed_header = header.toLowerCase().replace(/\W/g, '')
        object[parsed_header] = values[index];
        return object;
      }, {});
      return obj;
    });

    return array;
  }

  const onValidate = (e) => {
    e.preventDefault(e)
    const erros: any[] = [];
    let validado = true;

    const processando = state.arquivos.map((file, idx) => {
      if (!file) return Promise.resolve(true);
      return new Promise<void>((resolve) => {
        const fileReader = new FileReader();
        fileReader.onload = function (event) {
          try {
            if (!event || !event.target || !event.target.result) return resolve();
            const result: string = event.target.result as string;
            const csvHeader = result.slice(0, result.indexOf("\n")).split(";");
            if (csvHeader.length === 1) {
              throw new EvalError('CSV não possui separador ;')
            }
            const invalidHeader = validaCabecalho(csvHeader);
            if (invalidHeader.length > 0) {
              throw new EvalError(`CSV sem os cabeçalhos: ${invalidHeader.reduce((p, c) => p === '' ? c : p + ', ' + c, '')}`);
            }
          } catch (e:any) {
            const leg = { titulo: 'Erro Formato', descricao: e.message };
            const erro = {legendas: [leg], total_erros: 1, erro_cabecalho: true };
            erros.push(erro);
            validado = false;
            return resolve(); // não segue se erro está no cabeçalho
          }
          try {
            const csvOutput = event.target.result;
            const linhas = csvFileToArray(csvOutput);
            const encontrados = validateFamilies(linhas);
            setState({...state, legendas: encontrados.legendas, colunas: encontrados.colunas })
            erros.push(encontrados); 
            validado = validado && encontrados.total_erros === 0;
          } catch (e:any) {
            const leg = { titulo: 'Erro Interno', descricao: e.message };
            const erro = {legendas: [leg], total_erros: 1, erro_cabecalho: true};
            erros.push(erro);
            validado = true; // apenas para interno
          } finally {
            resolve()
          }
        };
        fileReader.readAsText(file);
      });
    });
    
    Promise.all(processando)
      .then(() => {
        setState({...state, erros, validado, validaAdmin: true });
      })
      .catch(e => console.error(e))
  }

  const sendUpload = async(admin: string) => {
    if(admin === "admin"){
      const toastId = toast.loading('Realizando Upload', {  });
      try {
        setState({...state, validado: false, tipoValido: false})
        if (state.arquivos) {
          var pid = state.portfolioId;
          let formData = new FormData();
          state.arquivos.forEach(a => formData.append("files", a));
          const id_upload = v4();

          await api.post('/backend/portfolios/' + pid + '/files/upload/' + id_upload, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
        }
        toast.success("Upload realizado Com Sucesso")
        setState({...state, validado: true, tipoValido: true})
        window.location.href = '/carteira-pre';
      } catch(error: any) {
        toast.error("Falha no upload");
      } finally {
        toast.dismiss(toastId)
      }
    } else {
      if(qtdErros > 0 && qtdTotalErros > 0){
        return toast.warning("Arquivo possui erros, não é possível fazer upload!")
      }
    
      const toastId = toast.loading('Realizando Upload', {  });
      try {
        setState({...state, validado: false, tipoValido: false})
        if (state.arquivos) {
          var pid = state.portfolioId;
          let formData = new FormData();
          state.arquivos.forEach(a => formData.append("files", a));
          const id_upload = v4();

          await api.post('/backend/portfolios/' + pid + '/files/upload/' + id_upload, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            }
          })
        }
        toast.success("Upload realizado Com Sucesso")
        setState({...state, validado: true, tipoValido: true})
        window.location.href = '/carteira-pre';
      } catch(error: any) {
        toast.error("Falha no upload");
      } finally {
        toast.dismiss(toastId)
      }
    }
  }

  return (
   <Container>
    <Main>
      <ContainerWrapper>
        <HeaderTitle
          title='Análise de Carteira de Recebíveis Imobiliários'
          variant='h4'
          style={{
            marginBottom: 16
          }}
        />
        <SubHeader
          title='Aqui você pode fazer upload de novas Carteiras.'
          style={{
            marginBottom: 16
          }}
        />

        <ContentBox>
          <TitleBox>Instruções de Preenchimento e Modelos</TitleBox>

          <TextBox>
            O upload de novas Carteiras é realizado através do nosso modelo em CSV,
            com os campos e cabeçalhos padronizados. Disponibilizamos o arquivo com as intruções de uso e modelo de preenchimento
            para sua comodidade.
          </TextBox>

          <DownloadButton
            variant='outlined'
          >
            Baixar Arquivo Modelo
          </DownloadButton>
        </ContentBox>

        <Typography
          style={{
            fontWeight: 500,

          }}
          my={4}
          variant='h4'
        >
          Arquivos
        </Typography>

        <FileUpload handler={handleOnChange} accept={'.csv'} />

        <Typography
          style={{
            fontWeight: 500,

          }}
          my={4}
          variant='h4'
        >
          Validação
        </Typography>

        {state.arquivos && state.arquivos.map((arq, idx) => (
          <ValidationsErrors key={Math.random().toString(10) }>
            {state.erros[idx] && state.erros[idx].total_erros > 0 &&
              <ValidationsDetails contracts={state.erros[idx].total_idvendas} errors={state.erros[idx].total_erros} data={state.erros[idx]} />}
            {state.validado && (!state.erros[idx] || state.erros[idx].total_erros === 0) && <>
              <Typography>Nenhum erro encontrado. Arquivo pronto para upload.</Typography>
            </>}
          </ValidationsErrors>
        ))}
        <FileButtons>
          <FileButtons>
            <ValidateButton
              disabled={!state.tipoValido}
              onClick={onValidate}
            >
              Validar
            </ValidateButton>
            <UploadButton
              onClick={() => sendUpload("user")}
            >
              Upload
            </UploadButton>
          </FileButtons>
          
          {(admin === 'true' && state.validaAdmin) && 
            <UploadButton onClick={() => sendUpload("admin")}>
              Upload Admin
            </UploadButton>
          }
        </FileButtons>

        <DataTableEnviados data={state.enviados} />
      </ContainerWrapper>
    </Main>
    <Aside>
      <AccordionMui num={1} active={true} title='Upload Carteira'/>
      <AccordionMui num={2} active={false} title='Proposta Comercial' data={['Seleção da Carteira' , 'Estruturação Sugerida', 'Envio de Proposta Comercial']}/>
      <AccordionMui num={3} active={false} title='Análise de Contratos e Documentos' data={['Aprovação dos Contratos de Cessão', 'Due Diligence das Empresas' , 'Due Diligence dos Contratos Individuais']}/>
      <AccordionMui num={4} active={false} title='Assinatura dos Documentos'/>
      <AccordionMui num={5} active={false} title='Envio de Recursos para a Empresa'/>
    </Aside>
   </Container>
  );
}

export default CarteiraPre;
