import {increaseData} from "."

const validateUF = (uf) => {
  const ufs = ["AC", "AL", "AP", "AM", "BA", "CE", "DF", "ES", "GO", "MA", "MT", "MS", "MG", "PA", "PB",
    "PR", "PE", "PI", "RJ", "RN", "RS", "RO", "RR", "SC", "SP", "SE", "TO"]
  const value = ufs.some((value) => value === uf.toUpperCase())
  return !value;
}

const validateTypeClient = (type) => {
  if(type !== 'PF' | 'PJ') {
    return true
  } else {
    return false
  }
}

const validateTypeSell = (type) => {
  if (!type) return true;
  const val = type.trim().toLowerCase().replace(/[^a-z0-9]/gi, '');
  const typeSell = ["taxas", 'taxa', "tabeladireta", "prsoluto", 'prosoluto']
  const searchTypeSell = typeSell.some((value) => value === val);
  return !searchTypeSell;
}

const validateCategoryProduct = (category) => {
  if (!category) return true;
  const val = category.trim().toLowerCase().replaceAll(' ','').replace(/[^a-z0-9]/gi, '');
  const categoryProduct = ["taxas","casaverdeeamarela","casaverdeamarela", "mcmv",'medio','mdio', "mdiopadrao",'mediopadrao','medioalto','mdioalto','altopadro','altopadrao']
  const searchCategoryProduct = categoryProduct.some((value) => value === val);

  return !searchCategoryProduct;
}

const validateStatusCobranded = (cobranded) => {
  if (!cobranded) return true;
  const val = cobranded.trim().toLowerCase().replace(/[^a-z0-9]/gi, '');
  const statusCobranded = ["normal", "terceirizada", "terceirizado",'quitada','quitado']
  const searchStatusCobranded = statusCobranded.find((value) => value === val)
  return !searchStatusCobranded;
}

const validateIndexator = (indexator) => {
  if (!indexator) return true;
  const val = indexator.trim().toLowerCase().replace(/[^a-z0-9$]/gi, ''); // deixar passar $, R$ = Pré (Direcional)
  const indexators = ["fixa","fixo","incc", "ipca", "igpm", "pr", 'pre', "r$", "real"];
  const searchIndexators = indexators.some((value) => value === val);
  return !searchIndexators;
}

const validateAlienation = (alienation) => {
  if (!alienation) return true;
  const val = alienation.trim().toLowerCase().replace(/[^a-z0-9]/gi, '');
  const alienations = ["sim", "no", 'nao']
  const searchAlienations = alienations.some((value) => value === val)
  return !searchAlienations;
}

const validateContract = (contract) => {
  let val1 = contract;
  if (!contract || contract === '') val1 = ''
  const val2 = val1.trim().toLowerCase().replace(/[^a-z0-9]/gi, '');
  const contracts = ['',"sim", "no", 'nao'] // vazio é considerado "não"
  const searchContracts = contracts.some((value) => value === val2)
  return !searchContracts;
}

export function validateFamily2(lines) {
  const erros = {total: 0, legendas: {}};
  lines.forEach((l, n) => {
    if (l.empty) return;
    let chave = 'idvenda_' + l.id_venda
    let validaUF = validateUF(l.ufempreendimento)
    let validaTipoCliente = validateTypeClient(l.tipocliente)
    let validaTipoVenda = validateTypeSell(l.tipovenda)
    let validaCategoriaProduto = validateCategoryProduct(l.categoriaproduto)
    let validaStatusCobranca = validateStatusCobranded(l.statuscobrana || l.statuscobranca)
    let validaIndexador = validateIndexator(l.indexador)
    let validaAlienacao = validateAlienation(l.alienaofiduciria || l.alienacaofiduciaria || l.alienacaofiduciria)
    let validaContrato = validateContract(l.contratocedido)

    if (validaUF) increaseData(erros, chave, "Erro_200", (n + 2), l.id_venda, 'UF incorreta');
    if (validaTipoCliente) increaseData(erros, chave, "Erro_201", (n + 2), l.id_venda, 'Tipo de cliente incorreto');
    if (validaTipoVenda) increaseData(erros, chave, "Erro_202", (n + 2), l.id_venda, 'Tipo de venda incorreto');
    if (validaCategoriaProduto) increaseData(erros, chave, "Erro_203", (n + 2), l.id_venda, 'Categoria de produto incorreta');
    if (validaStatusCobranca) increaseData(erros, chave, "Erro_204", (n + 2), l.id_venda, 'Status de cobrança incorreto');
    if (validaIndexador) increaseData(erros, chave, "Erro_205", (n + 2), l.id_venda, 'Índice de correção incorreto');
    if (validaAlienacao) increaseData(erros, chave, "Erro_206", (n + 2), l.id_venda, 'Alienação fiduciária incorreta');
    if (validaContrato) increaseData(erros, chave, "Erro_207", (n + 2), l.id_venda, 'Contrato cedido incorreto');
  })
  return erros;
}
