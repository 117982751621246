import { GridColDef } from '@mui/x-data-grid';
import { parsePct, parseRS, parseInt } from '../helpers';

export const ColumnsStates: GridColDef[] = [
  { field: 'state', headerName: 'Estado', flex: 1 },
  { field: 'bucket', headerName: 'Carteira', valueFormatter: parseRS, flex: 1 },
  { field: 'bucket_pct', headerName: 'Carteira (%)', valueFormatter: parsePct, flex: 1 },
  { field: 'qty', headerName: '# Contratos', flex: 1, valueFormatter: parseInt },
  { field: 'qty_pct', headerName: '# Contratos (%)', valueFormatter: parsePct, flex: 1 },
];

export const DataStates = [
    {
      id: 0,
      state: "SP",
      qty: 15,
      qty_pct: 1,
      bucket: 24506891,
      bucket_pct: 1,
    },
  ];
