import { Box, Button, styled } from "@mui/material";

export const Container = styled(Box)`
 padding: 3rem 3rem;
 
`;

export const Submit = styled(Button)`
 background-color: #F46F62;
 border: 1px solid #F46F62;
 color: #FFF;
 padding: .5rem 2rem;
 transition: .3s;
 border-radius: 2rem;
 margin: 1rem 0;

 &:hover {
  border: 1px solid #F46F62;
  background-color: #FFF;
  color: #F46F62;
 }
`;