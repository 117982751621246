import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';

export const ColumnsPrazosContratos: GridColDef[] = [
  { field: 'type', headerName: 'Cliente', flex: 2 },
  { field: 'sale_min_date', headerName: 'Mín.', flex: 2 },
  { field: 'sale_max_date', headerName: 'Máx.', flex: 2 },
  { field: 'pay_min_date', headerName: 'Mín.', flex: 2 },
  { field: 'pay_max_date', headerName: 'Máx.', flex: 2 },
  { field: 'parc_min', headerName: 'Mín.', flex: 1 },
  { field: 'parc_med', headerName: 'Média', flex: 1 },
  { field: 'parc_max', headerName: 'Máx.', flex: 1 },
];

export const ColumnsGroupPrazosContratos: GridColumnGroupingModel = [
  {
    groupId: 'Data da Venda',
    children: [{ field: 'sale_min_date' }, { field: 'sale_max_date'}],
  },
  {
    groupId: 'Data de Vencimento',
    children: [{ field: 'pay_min_date' }, { field: 'pay_max_date'}],
  },
  {
    groupId: 'Parcelas',
    children: [{ field: 'parc_med' }, { field: 'parc_min'}, { field: 'parc_max'}],
  },
];

export const DataPrazosContratos = [
    {
      id: 0,
      type: 'PF',
      sale_min_date: (new Date()).toLocaleDateString('pt-br'),
      sale_max_date: (new Date()).toLocaleDateString('pt-br'),
      pay_min_date: (new Date()).toLocaleDateString('pt-br'),
      pay_max_date: (new Date()).toLocaleDateString('pt-br'),
      parc_med: 54,
      parc_min: 2,
      parc_max: 180,
    },
    {
      id: 1,
      type: 'PJ',
      sale_min_date: (new Date()).toLocaleDateString('pt-br'),
      sale_max_date: (new Date()).toLocaleDateString('pt-br'),
      pay_min_date: (new Date()).toLocaleDateString('pt-br'),
      pay_max_date: (new Date()).toLocaleDateString('pt-br'),
      parc_med: 80,
      parc_min: 15,
      parc_max: 150,
    },
  ];

export const ColumnsEstPrazosContratos: GridColDef[] = [
  { field: 'type', headerName: 'Estatística', flex: 1, align: 'center', headerAlign: 'center' },
  { field: 'bucket', headerName: 'Valor', flex: 1, align: 'center', headerAlign: 'center' },
];

export const DataEstPrazosContratos = [
  {
    id: 0,
    type: 'Mínimo',
    bucket: 0,
  },
  {
    id: 1,
    type: 'Primeiro Quartil',
    bucket: 1,
  },
  {
    id: 2,
    type: 'Mediana',
    bucket: 5,
  },
  {
    id: 3,
    type: 'Média',
    bucket: 6.7,
  },
  {
    id: 4,
    type: 'Terceiro Quartil',
    bucket: 18.8,
  },
  {
    id: 5,
    type: 'Máximo',
    bucket: 45,
  },
];
