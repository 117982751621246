import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import { CustomSwitch } from './styles';
import { useAppDispatch, useAppSelector } from '../../../../store';
import { selectedPartner } from '../../../../store/slices/partners';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', width: 70 },
  { field: 'name', headerName: 'Nome', width: 130 },
  {
   field: 'selected',
   headerName: 'Selecione',
   sortable: false,
   width: 160,
   renderCell: (params) => {
    return <CustomSwitch color='error' disabled checked={params.row.selected} onClick={() => {}}/>
   },
  }
];

export const DataTable = () => {
  const partners = useAppSelector(state => state.partner);
  const dispatch = useAppDispatch();
  const [loadedPartners , setLoadedPartners] = React.useState<boolean>(false);
  const [partnersLocal, setPartnersLocal] = React.useState<any[]>([]);

  const customPartner = localStorage.getItem("@user:partner");
  
  const loadPartners = () => {
    if(partners[0].name !== ""){
      if(customPartner){
        const partnersLoaded = partners.map((partner) => {
          if(partner.id === Number(customPartner)){
            return {
              ...partner,
              selected: true
            }
          } else {
            return {
              ...partner,
              selected: false
            }
          }
        })
        setPartnersLocal(partnersLoaded)
        return setLoadedPartners(true)
      } else {
        setLoadedPartners(true)
        return setPartnersLocal(partners)
      }
    }
  }

  React.useEffect(() => {
    loadPartners()
  }, [partners])

  const handleSelectPartner = (params: any) => {
    localStorage.removeItem("@user:partner")
    const partnersCustom = partners.map((partner) => {
      if(partner.name === params?.row?.name){
        localStorage.setItem("@user:partner", String(partner.id))
        return {...partner, selected: !partner.selected}
      } else {
        return {...partner, selected:false}
      }
    })
    setPartnersLocal(partnersCustom)
    dispatch(selectedPartner(partnersCustom))
  }

  return (
    <div style={{ height: 400, width: '100%' }}>
      {<DataGrid
        rows={[...partnersLocal]}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 5 },
          },
        }}
        pageSizeOptions={[5, 10]}
        rowSelection={false}
        onRowClick={handleSelectPartner}
        style={{
          backgroundColor: "#FAFAFA"
        }}
      />}
    </div>
  );
}
