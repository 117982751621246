import React, { useState } from 'react';

import { Container, ContainerListItem } from './styles';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DataTable } from './DataTable';

interface IValidationDetails {
  errors: number;
  contracts: number;
  data: any;
}

const ValidationsDetails = ({ contracts, errors, data }: IValidationDetails) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  return (
   <Container>
    {errors > 0 && <Accordion expanded={expanded} onChange={() => setExpanded(value => !value)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ marginRight: 5 }}>
            Foram encontrados <strong>{errors}</strong> erros, em <strong>{contracts}</strong> contratos.
          </Typography>
        </AccordionSummary>
         <AccordionDetails>
          <ContainerListItem>
           <ul>
             { data.legendas.map((l,i) => <li key={i}>{l.titulo} - {l.descricao}</li>) }
           </ul>
          </ContainerListItem>
          <DataTable data={data} />
         </AccordionDetails>
      </Accordion>}
   </Container>
  );
}

export default ValidationsDetails;
