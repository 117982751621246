import React from 'react';

import { Title } from './styles';
import { TypographyProps } from '@mui/material';

interface IHeaderTitle extends TypographyProps {
 title: string;
}

const HeaderTitle = ({ title, variant, ...props }: IHeaderTitle) => {
  return (
   <Title variant={variant} {...props}>
    {title}
   </Title>
  );
}

export default HeaderTitle;