import styled from "styled-components";

export const Container = styled.div`
 width: 100%;
 border: none;
 padding: 3rem;
 background-color: #FAFAFA;
 border-radius: 8px;
 box-shadow: 0px 4px 5px -1px rgba(0,0,0,0.39);
 margin-bottom: 1rem;
`;

export const CustomFrame = styled.iframe`
 width: 100%;
 border: none;
 height: 1200px;
`; 