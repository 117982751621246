import React from 'react';

import { Container } from './styles';

interface IStatusFilterBoxProps {
 row: Record<string, string>;
}

const StatusFilterBox = ({ row }: IStatusFilterBoxProps) => {
  return (
   <Container status={row?.status}>
    {row?.status}
   </Container>
  );
}

export default StatusFilterBox;