import { Box, Button, Grid, Typography, styled } from "@mui/material";

export const Wrapper = styled(Grid)`
 padding: 7rem 3rem 0 3rem;
 min-width: 1200px;
`;

export const Aside = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  background-color: #FAFAFA;
  padding: 7rem 1rem;
  
  @media(max-width: 2010px) {
   display: none;
  }
 `;

 export const ContentBox = styled(Box)`
  background-color: #EFEFEF;
  border-radius: 8px;
  padding: 1rem 0 2rem 2rem;
  box-shadow: 0px 5px 3px 0px rgba(0,0,0,0.31);
 `;

 export const TitleBox = styled(Typography)`
  margin-bottom: 1rem;
  font-weight: 500;
 `;

 export const TextBox = styled(Typography)`
  margin-bottom: 1rem;
  font-size: 0.8rem;
  font-weight: 300;
 `;

 export const DownloadButton = styled(Button)`
  background-color: #EFEFEF;
  border-radius: 100px;
  color: #000;
  border: 1px solid #000;
  padding: .5rem 2rem;
  transition: .3s;

  &:hover {
   border: 1px solid #000;
   background-color: #000;
   color: #FFF;
  }

  font-weight: 500;
 `;

 export const ValidateButton = styled(Button)`
  background-color: #FFF;
  border-radius: 100px;
  color: #000;
  border: 1px solid #000;
  padding: .5rem 2rem;
  transition: .3s;
  width: 200px;

  &:hover {
   border: 1px solid #000;
   background-color: #000;
   color: #FFF;
  }
 `;

 export const UploadButton = styled(Button)`
 background-color: #F46F62;
 border: 1px solid #F46F62;
 padding: 0.5rem 2rem;
 border-radius: 30px;
 color: #FFF;
 transition: .3s;
 cursor: pointer;
 width: 200px;

 &:hover {
  border: 1px solid #F46F62;
  background-color: #FFF;
  color: #F46F62
 }
 `;

 export const ValidationsErrors = styled(Box)`
  padding-left: 1rem;
 `;
