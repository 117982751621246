import React from 'react';

import { Container, SubTitle, Title } from './styles';
import { Grid } from '@mui/material';
import Card from './components/Card';
import DataTable from './components/DataTable';
import DataTableAntecipacaoCreditoVinculado from './components/DataTableAntecipacaoCreditoVinculado';
import DataTableSaldoInadimplencia from './components/DataTableSaldoInadimplencia';

const AnalisePosVenda = () => {
  return (
   <Container>
    <Title variant='h4'>Análise Pós-Venda</Title>

    <SubTitle variant='h5'>Visão geral</SubTitle>
    <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
     <Grid item xs={12} lg={3}>
      <Card title='Operação' description='OP003'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Período de Apuração' description='de 26/04/2024 até 25/05/2024'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Total de Contratos' description='51'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Contratos Ativos' description='439'/>
     </Grid>

    </Grid>

    <SubTitle variant='h5'>Fluxo de Caixa</SubTitle>
    <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
     <Grid item xs={12} lg={3}>
      <Card title='Saldo pré-pagamento me' description='R$ 700.000,00'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Esperado a Receber no mês de Amortização' description='R$ 1.090.000,00'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Recebido no mês de parcelas do mês Amortização' description='R$ 464.761,11'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Recebido no mês de Antecipação Amortização' description='R$ 333.616,68'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Recebido no mês de Inadimplência Amortização' description='R$ 220.000,68'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Recebido no mês de juros e mora' description='R$ 371.500,68'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Saldo devedor total' description='R$ 88.333.616,68'/>
     </Grid>
    </Grid>

    <DataTable data={[]}/>
    <DataTableAntecipacaoCreditoVinculado data={[]}/>
    <DataTableSaldoInadimplencia data={[]}/>

    <SubTitle variant='h5'>Provisionamento e Inadimplência</SubTitle>
    <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
     <Grid item xs={12} lg={3}>
      <Card title='Saldo de Parcelas em Aberto' description='R$ 77.189.966,47'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Contratos Inadimplentes > 90' description='R$ 3.292.456,00'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Saldo de Inadimplência do Mês' description='R$ 286.596,36'/>
     </Grid>

     <Grid item xs={12} lg={3}>
      <Card title='Recebido no mês de Antecipação Amortização' description='R$ 333.616,68'/>
     </Grid>
    </Grid>
   </Container>
  );
}

export default AnalisePosVenda;