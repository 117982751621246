import { AccordionDetails, Chip, ChipProps, styled } from "@mui/material";

interface ICustomChip extends ChipProps {
 active: boolean;
}

export const CustomAccordionDetails = styled(AccordionDetails)`
 margin-left: 2rem;
`;

export const CustomChip = styled(Chip)<ICustomChip>`
 background-color: ${ ({ active }) => active ? '#F46F62' : '#EBEBEB' };
 color: ${ ({ active }) =>  active ? '#FFF' : '#000' };
`;