import React from 'react';

import { Container, CustomDataGrid, WrapperTable } from './styles';
import { Typography } from '@mui/material';

export interface ITwoChartTable {
  chart: any;
  title: string;
  data: any;
  columns: any;
  mainFlex?: number;
}

const TwoChartTable = (input: ITwoChartTable) => {
  return (
    <Container>
      <WrapperTable style={{ flex: input.mainFlex || 1 }}>
        <Typography variant='h5'>
          <b>{input.title}</b>
        </Typography>
        {<CustomDataGrid
          rows={input.data}
          columns={input.columns}
          hideFooter={true}
          rowSelection={false}
        />}
      </WrapperTable>
      <div style={{ flex: 1 }}>
        {input.chart}
      </div>
    </Container>
  );
}

export default TwoChartTable;
