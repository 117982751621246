import React from 'react';

import { Container, CustomDataGrid, WrapperTable } from './styles';
import { Typography } from '@mui/material';

export interface ITwoCharts {
  chart1: any;
  chart2: any;
}

const TwoCharts = (input: ITwoCharts) => {
  return (
    <Container>
      <div style={{ flex: 1 }}>
        {input.chart1}
      </div>
      <div style={{ flex: 1 }}>
        {input.chart2}
      </div>
    </Container>
  );
}

export default TwoCharts;
