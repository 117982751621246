/* eslint-disable no-template-curly-in-string */
import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Container } from './styles';
import { IGraph } from '../../datasetInterface';

interface IInput {
  data: IGraph
}

const parsePct = (value: string) => Math.round(parseFloat(value)*100);

const ChartLTV = (input: IInput) => {
 const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      title: {
        text: "LTV"
      },
      categories: input.data?.x.map(((x,i) => `${parsePct(x)}%`)),
    },
    yAxis: {
      min: 0,
      title: {
        text: "# de Contratos"
      },
    },
    series: [{
      type: 'column',
      name: "# de Contratos",
      data: input.data?.y,
      color: "#F46F62"
    }]
  };
  return (
   <Container>
     <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
   </Container>
  );
}

export default ChartLTV;
