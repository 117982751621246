import React, { useEffect } from 'react';

import { Container  } from './styles';
import { Typography } from '@mui/material';
import { DataTable } from './components/Table';
import { useAppDispatch, useAppSelector } from '../../store';
import { loadPartners } from '../../store/slices/partners';

const Parceiros: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadPartners());
  }, []);

  return (
   <Container>
      <Typography
        variant='h3'
        color="#F46F62"
        style={{
          fontWeight: 300,
        }}
      >
        Parceiros
      </Typography>

      <Typography
        variant='h5'
        color="#000"
        style={{
          fontWeight: 300,
          marginBottom: 30
        }}
      >
        Aqui você pode selecionar qual carteira de parceiro.
      </Typography>
      <DataTable />
   </Container>
  );
}

export default Parceiros;
