import React from 'react';
import { Container, CustomFrame } from './styles';
import { Typography } from '@mui/material';

interface S3View {
 url: string;
 title: string;
}

const S3View = ({ url, title }: S3View) => {
  return (
   <Container>
    <Typography variant='h5'>
     {title}
    </Typography>
    <CustomFrame src={url}/>
   </Container>
  );
}

export default S3View;