import React from 'react';
import {
  Container,
  CustomFile,
  CustomLabel
} from './styles';
import { Title } from './stylesMui';

const FileUpload = ({ handler, accept }: any) => {
  return (
    <Container>
      <form>
        <Title>Arquivos *</Title>
        <CustomLabel htmlFor="arquivo">Selecionar arquivos</CustomLabel>
        <CustomFile type='file' id='arquivo' placeholder="" onChange={(e) => handler(e)} autoComplete="off" accept={accept} multiple />
      </form>
    </Container>
  );
}

export default FileUpload;
