
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { Controller, useFormContext } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DatePicker } from './styles';
import { Typography } from '@mui/material';

// import { Container } from './styles';

type IInputDate = {
 name: string;
 label?: string;
}

const InputDate = ({ name, label }: IInputDate) => {
  const { control } = useFormContext()
  const dateNow = dayjs().format("YYYY-MM-DD").toString()
  return (
   <Controller 
    name={name}
    defaultValue={dateNow}
    control={control}
    render={({ field }) => (
    <>
      <Typography>{label}</Typography>
      <DatePicker 
        type='date'
        placeholder='Selecione a data'
        {...field}
      />
    </>  
    )}
   />
  );
}

export default InputDate;