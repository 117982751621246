import {increaseData} from ".";
import {isValidDate} from "./family1";

export const parseMonetario = (value) => {
  if (!value || value === '') return 0;
  const val2 = value.toString().toLowerCase().trim();
  if (['-', 'ni', 'na', 'n/a'].some(x => x === val2)) {
    return 0;
  }
  const val = val2.replace(/\.+/g, '').replace(',', '.')
  return parseFloat(val || 0);
}

const validateDateAndValueReceived = (lines) => {
  let valorRecebido = parseMonetario(lines.valorrecebido);
  const data = isValidDate(lines.datarecebimento);
  if (data.isValid && !data.isNull) return valorRecebido === 0;
  return false;
}

const validateNotDateAndValueReceived = (lines) => {
  let valorRecebido = parseMonetario(lines.valorrecebido);
  const data = isValidDate(lines.datarecebimento);
  if (data.isValid && data.isNull) return valorRecebido !== 0;
  return false;
}

const validateRateInterest = (lines) => {
  let rate = parseMonetario(lines.taxajuros);
  let valueNominal = parseMonetario(lines.valornominaldasparcelas);
  let valueAntecipacao = parseMonetario(lines.valorparaantecipao || lines.valorparaantecipacao);
  if (rate === 0) {
    return valueAntecipacao !== valueNominal;
  }
  return false
}

const validateRateInterestInvert = (lines) => {
  let rate = parseMonetario(lines.taxajuros);
  let valueNominal = parseMonetario(lines.valornominaldasparcelas);
  let valueAntecipacao = parseMonetario(lines.valorparaantecipao || lines.valorparaantecipacao);
  if (valueAntecipacao !== valueNominal) {
    return rate === 0;
  }
  return false
}

const validateValuePrincipal = (lines) => {
  let valueNominal = parseMonetario(lines.valornominaldasparcelas);
  let valueAntecipacao = parseMonetario(lines.valorparaantecipao || lines.valorparaantecipacao);

  return valueAntecipacao > valueNominal;
}

export function validateFamily6(lines) {
  const erros = {total: 0, legendas: {}};
  lines.forEach((l, n) => {
    if (l.empty) return;
    let chave = 'idvenda_' + l.id_venda
    let validaDataRecebimentoValorRecebido = validateDateAndValueReceived(l)
    let validaSemDataRecebimentoValorRecebido = validateNotDateAndValueReceived(l)
    let validaTaxaJurosPmtAnt = validateRateInterest(l)
    // let validaTaxaJurosPmtAntInvert = validateRateInterestInvert(l)
    let validaNominalPrincipal = validateValuePrincipal(l)

    if (validaDataRecebimentoValorRecebido) increaseData(erros, chave, "Erro_600", (n + 2), l.id_venda, 'Parcela com data de recebimento mas valor recebido igual 0');
    if (validaSemDataRecebimentoValorRecebido) increaseData(erros, chave, "Erro_601", (n + 2), l.id_venda, 'Parcela sem recebimento mas valor recebido diferente de 0');
    if (validaTaxaJurosPmtAnt) increaseData(erros, chave, "Erro_602", (n + 2), l.id_venda, 'Valor de taxa de juros igual 0 porém valores nominal e de antecipação não são iguais');
    // if(validaTaxaJurosPmtAntInvert) increaseData(erros, chave, "Erro_603", (n+2), l.id_venda, 'Valores pmt e vf iguais porém taxa de juros não é igual a zero');
    if (validaNominalPrincipal) increaseData(erros, chave, "Erro_604", (n + 2), l.id_venda, 'Valor de antecipação maior que o valor nominal');
    // 605 é o 604 invertido
  })
  return erros;
}
