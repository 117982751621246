import { Box, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const Container = styled(Box)`
 display: flex;
 width: 100%;
 overflow: auto;
`;


export const WrapperTable = styled(Box)`
  background-color: #FFF;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 5rem;
`;

export const CustomDataGrid = styled(DataGrid)`
  background-color: #FFF;

.MuiDataGrid-main > .MuiDataGrid-withBorderColor {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.MuiDataGrid-columnHeaderTitle {
 font-weight: 600;
}

.MuiDataGrid-footerContainer {
  border-bottom: 2px solid #000;
}

border: none;
    border-bottom: 1px solid #000;
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
