import { GridColDef } from '@mui/x-data-grid';
import { parseRS, parseInt } from '../helpers';

export const ColumnsCarteira: GridColDef[] = [
  { field: 'client', headerName: 'Tipo Cliente', flex: 1, align: 'center', headerAlign: 'center' },
  { field: 'bucket_origin', headerName: 'Carteira Originada', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseRS },
  { field: 'bucket', headerName: 'Carteira', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseRS },
  { field: 'contract', headerName: '#Contratos', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseInt },
];

export const DataCarteira = [
    {
      id: 0,
      client: "PF",
      bucket_origin: "R$ 15.436.296",
      bucket: "R$ 7.391.464,1",
      contract: "49"
    },
    {
      id: 1,
      client: "PJ",
      bucket_origin: "R$ 1.652.866",
      bucket: "R$ 831.584,8",
      contract: "5"
    },
    {
      id: 2,
      client: "Consolidado",
      bucket_origin: "R$ 17.089.162",
      bucket: "R$ 8.223.048,9",
      contract: "54"
    },
  ];
