import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import HeaderTitle from '../../components/HeaderTitle';
import SubHeader from '../../components/SubHeader';
import S3View from './components/S3View';
import { useAppSelector } from '../../store';
import { toast } from 'react-toastify';
import { api } from '../../api';

const styleSubHeader: React.CSSProperties = {
 marginBottom: 16
}

const Dashboard = () => {
  const [portfolios, setPortfolios] = useState<Array<any>>([]);
  const partners = useAppSelector(state => state.partner);
  const partner = partners.find((part) => part.selected === true);

  const loadDashboard = async() => {
   const response = await api.get("/backend/partners/"+ partner?.id +"/portfolios");
   const ports = response.data.data.portfolios.filter((port: any) => !!port.dashboard1)
   setPortfolios(ports)
  }

  useEffect(() => {
   toast.promise(loadDashboard, {
    error: "Erro ao carregar dashboard",
    pending: "Carregando dashboard",
    success: "Dashboard carregado"
   })
  }, [])

  return (
   <Container>
    <HeaderTitle 
      title='Dashboard'
      variant='h3'
    />
    <SubHeader 
     title='Gerencie e visualize dashboard'
     style={styleSubHeader}
    />

    {portfolios.map((portfolio) => (
     <S3View url={portfolio?.dashboard1} title={portfolio?.name}/>
    ))}
   </Container>
  );
}

export default Dashboard;