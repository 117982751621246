import {increaseData} from ".";
import {parseMonetario} from "./family6";

export function validateFamily8(lines) {
  const erros = {total: 0, legendas: {}};
  const V800 = {};

  // let valueAntecipacao = parseFloat((lines.valorparaantecipao).replace(/\D/g, '') || 0);

  lines.forEach((l, n) => {
    if (l.empty) return;
    let chave = 'idvenda_' + l.id_venda;

    if (!V800[chave]) {
      V800[chave] = {
        contador: parseMonetario(l.valorparcelaprincipal),
        contador2: parseMonetario(l.valorvenda),
        linha: n + 2,
        id_venda: l.id_venda,
      };
    } else {
      V800[chave].contador += parseMonetario(l.valorparcelaprincipal)
    }
  })

  Object.keys(V800).forEach(chave => {
    const ltv = (V800[chave].contador / V800[chave].contador2);

    if (ltv <= 0) increaseData(erros, chave, 'Erro_800', V800[chave].linha, V800[chave].id_venda, 'LTV menor ou igual a zero')
    else if (ltv > 1) increaseData(erros, chave, 'Erro_801', V800[chave].linha, V800[chave].id_venda, 'LTV deve ser menor do que 1')
  })

  return erros;
}
