import { Grid } from '@mui/material';
import React from 'react';
import InputText from '../../../../components/InputText';
import InputData from '../../../../components/InputData';

// import { Container } from './styles';

const EditaFiltro: React.FC = () => {
  return (
    <Grid container columnSpacing={3} marginBottom={3} xs={12}>
      <Grid item xs={12} lg={6}>
        <InputText 
          name='nome_filtro'
          label='Nome Filtro'
          placeholder='Nome Filtro'
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <InputData 
          name='status'
          label='Status'
          data={[
            {
              label: 'Publicado',
              value: 'publicado',
            },
            {
              label: 'Oculto',
              value: 'oculto',
            },
            {
              label: 'Salvo',
              value: 'salvo',
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default EditaFiltro;