import React from 'react';
import { Button, Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import HeaderTitle from '../../components/HeaderTitle';
import SubHeader from '../../components/SubHeader';
import InputDate from '../../components/InputDate';
import { Form } from './styles';
import { Container, Submit } from './stylesMui';
import InputText from '../../components/InputText';
import { Params, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const Filtros = () => {
  const { fileId, partinerId } = useParams();
  const methods = useForm({
    defaultValues: {
      data_maxima_vencimento: 1,
    },
  });
  methods.setValue('data_maxima_vencimento', 1);

  const onSubmit = (data: any) => {
    toast.promise(async () => {
      const response = await axios.post('/backend/portfolios/' + partinerId + '/files/' + fileId + '/filter', data);
    }, {
      error: 'Falha na requisição',
      pending: 'Carregando dados',
      success: 'Dados carregados',
    });
  };

  return (
    <Container>
      <HeaderTitle
        title='Filtros - Carteira de Recebíveis Imobiliários'
        variant='h3'
      />
      <SubHeader
        title='FILTROS'
        mx={2}
        style={{
          fontWeight: 500,
        }}
        fontSize={24}
      />

      <Form onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid container spacing={7} style={{}}>
          <FormProvider {...methods}>
            <Grid item xs={12} lg={4}>
              <InputDate
                name='data_maxima_vencimento'
                label='Data Máxima Vencimento'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='valor_nominal_parcela_minimo'
                type='number'
                label='Valor Nominal Parcela Mínimo'
                step='100'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='saldo_devedor_minimo'
                label='Saldo Devedor Mínimo'
                step='100'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='saldo_devedor_maximo'
                label='Saldo Devedor Máximo'
                step='1000'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='qtd_parcelas_abertas_minima'
                label='Quantidade Parcelas Abertas Mínima'
                step='1'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='qtd_parcelas_pagas_minima'
                label='Quantidade Parcelas Pagas Mínima'
                step='1'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='aging_maximo'
                label='Aging Máximo'
                step='10'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='ltv_maximo'
                label='Ltv Máximo'
                step='0.1'
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <InputText
                name='saldo_minimo_spe'
                label='Saldo Mínimo SPE'
                step='100'
              />
            </Grid>
          </FormProvider>
        </Grid>
        <Submit type='submit'>Analisar Carteira</Submit>
      </Form>
    </Container>
  );
};

export default Filtros;
