import React from 'react';

import { Container, Content, Description, Header, Title } from './styles';

interface ICard {
 title: string;
 description: string;
}

const Card = ({ title, description }: ICard) => {
  return (
   <Container>
    <Header>
     <Title>{title}</Title>
    </Header>

    <Content>
     <Description>{description}</Description>
    </Content>
   </Container>
  );
}

export default Card;