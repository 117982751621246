import React, { useEffect, useRef, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import highchartsMap from 'highcharts/modules/map';

import { Container } from './styles';

const MapChart = ({ data }) => {
 const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
 const [map, setMap] = useState();
 const [dados, setDados] = useState([[]])
 highchartsMap(Highcharts);

 const loadMap = async() => {
  const topology = await fetch(
    'https://code.highcharts.com/mapdata/countries/br/br-all.topo.json'
  ).then(response => response.json());
  setMap(topology)
  setDados(data)
 }

const options = {
  chart: {
    map: map
  },
  title: {
    text: ''
  },
  exporting: {
    sourceWidth: 600,
    sourceHeight: 500
  },
  mapNavigation: {
    enabled: false
  },
  colorAxis: {
    min: 0,
    type: 'linear',
    minColor: '#F9E9E8',
    maxColor: '#F46F62',
  },
  series: [{
    data: dados,
    name: 'Contratos',
    states: {
        hover: {
            color: '#f46F62'
        }
    },
    dataLabels: {
        enabled: true,
        format: '{point.name}'
    }
  }]
};

  useEffect(() => {
    loadMap()
  }, [data])

  return (
   <Container>
     <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      constructorType="mapChart"
    />
   </Container>
  );
}

export default MapChart;
