import { Box, Grid, Typography, styled } from "@mui/material";

export const Container = styled(Box)`
 padding: 8rem 1rem 0.5rem 3rem;
 max-width: 1400px;
`;

export const Title = styled(Typography)`
 color: #F46F62;
 font-weight: 300;
 margin-bottom: 3rem;
`;

export const SubTitle = styled(Typography)`
 color: #000;
 font-weight: bold;
 margin-bottom: 1rem;
`;