import React, { useEffect, useState } from 'react';

import { Container, CustomDrawer, CustomSelect, FilterBox, Title, WrapperContent, WrapperDrawer, CustomChipTitle } from './styles';
import { Button, FormControl, InputLabel, MenuItem } from '@mui/material';
import AccordionMui from './components/Accordion';
import { ColumnsGeral } from './datasets/DataTableGeral';
import { ColumnsCarteira } from './datasets/DataTableCarteira';
import ChartContracts from './components/ChartContracts';
import ChartFluxBuyer from './components/ChartFluxBuyer';
import { ChartColumnFlux, ChartColumnFluxAccumulated } from './components/ChartColumnFlux';
import MapChart from './components/MapChart';
import { OneTable } from './components/OneTable';
import TwoChartTable from './components/TwoChartTable';
import { ColumnsContracts, ColumnsParcels } from './datasets/DataContracts';
import { ColumnsStates } from './datasets/DataStates';
import { ColumnsProducts } from './datasets/dsProdutos';
import { ColumnsIndexes } from './datasets/dsIndexes';
import { CustomChip } from './components/Accordion/styles';
import Typography from '@mui/material/Typography';
import FiltersAccordionMui from './components/Filters';
import { useParams } from 'react-router-dom';
import Filtros from '../Filtros';
import {
  ColumnsEstPrazosContratos,
  ColumnsGroupPrazosContratos,
  ColumnsPrazosContratos,
  DataEstPrazosContratos,
  DataPrazosContratos,
} from './datasets/DataPrazosContratos';
import ChartEstContracts from './components/ChartEstContracts';
import { ColumnsAbertoHabitese, ColumnsGroupAbertoHabitese, ColumnsTotalHabitese, DataAbertoHabitese } from './datasets/DataAbertoHabitese';
import { toast } from 'react-toastify';
import { api } from '../../api';
import { IAnalysisData } from './datasetInterface';
import ChartNPLHistory from './components/ChartNPLHistory';
import TwoCharts from './components/TwoCharts';
import ChartNPLHistory2 from './components/ChartNPLHistory2';
import { ColumnsLTV } from './datasets/DataLTV';
import ChartLTV from './components/ChartLTV';

const AnaliseCarteira = () => {
  const { fileId, filterId, filterName } = useParams();
  const [filtro, setFiltro] = useState<any>();
  const [desabilitar, setDesabilitar] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [prov, setProv] = useState<number>(0);
  const [list, setList] = useState<any[]>([]);
  const [dados, setDados] = useState<IAnalysisData>({
    aberto_habitese: [],
    categorias: [],
    composicao: [],
    geografia: [],
    geografia_map: [],
    geral: [],
    indices: [],
    num_contratos_est: [],
    num_contratos_graph: { x: [], y: [] },
    prazo_contratos: [],
    total_habitese: [],
    valor_contratos: [],
    valor_contratos_graph: { x: [], y: [] },
    npl_history_graph: { x: [], y: [] },
    npl_history_open_graph: { x: [], y: [], y2: [] },
    ltv_presente_quartis: [],
    ltv_principal_quartis: [],
    ltv_presente_graph: { x: [], y: [] },
    ltv_principal_graph: { x: [], y: [] },
    flux_graph: { x: [], y: [] },
  });
  const [dadosGeral, setDadosGeral] = useState<any[]>([])

  const handleChangeFiltro = (event: any) => {
    const novo = event.target.value as string;
    if (list) {
      setFiltro(list.find(x => x.id === novo));
      getAnalysis(novo).then();
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(() => {
    getFilters()
      .then((l) => {
        return getAnalysis(filterId);
      });
  }, []);

  const getFilters = async(): Promise<any[]> => {
    try {
      const response = await api.get('/backend/portfolios/analysis/files/' + fileId + '/list');
      const json:any[] = response.data.data;
      setList(json);
      return json;
    } catch (e) {
      setList([]);
      return [];
    }
  }

  const getAnalysis = async (idFilter?: any) => {
    const toastId = toast.loading('Carregando Carteira Analisada');
    try {
      const response = await api.get('/backend/portfolios/analysis/files/' + fileId + '/filters' + (!!idFilter ? `/${idFilter}` : null));
      const json = response.data.data;
      setDados(json.analysis);
      if (json.analysis.geral && json.analysis.geral[4])
        setProv(Math.round(parseFloat(json.analysis.geral[4].value)));
      setFiltro(json.filter);
    } catch (error) {
      toast.error('Não Foi Possível Carregar Carteira Analisada');
    } finally {
      toast.dismiss(toastId);
    }
  };

  useEffect(() => {
    const dadosGeralCustomized = dados.geral.map((item: any) => {
      if(item.type === 'Cedente/Emissor'){
        return {
          ...item,
          value: '-'
        }
      } else {
        return item
      }
    })

    setDadosGeral([...dadosGeralCustomized])
  }, [dados])

  return (
    <Container>
      <WrapperContent>
        <Title variant='h4'>
          Análise de Carteira - Imobiliária
        </Title>

        <FilterBox>
          <CustomChipTitle onClick={handleOpen}>
            <CustomChip label={2} active />&nbsp;
            <Typography sx={{ marginRight: 5 }}>
              Proposta Comercial
            </Typography>
          </CustomChipTitle>

          {!desabilitar && <FormControl>
            <InputLabel>Filtro</InputLabel>
            <CustomSelect
              value={filtro}
              label='Filtro'
              onChange={handleChangeFiltro}
            >
              { list && list.map(x => <MenuItem value={x.id}>{x.name}</MenuItem>)}
            </CustomSelect>
          </FormControl>}
        </FilterBox>

        <FiltersAccordionMui fileId={fileId ?? ''}/>

        <Typography variant='h5' fontWeight="bold" style={{ paddingLeft: 16, marginBottom: 32 }}>Análise: {filterName}</Typography>

        <OneTable title='Visão Geral' columns={ColumnsGeral} data={dadosGeral} />
        <OneTable title='Composição' columns={ColumnsCarteira} data={dados.composicao} />
        <TwoChartTable title='Valor dos Contratos' chart={<ChartContracts data={dados.valor_contratos_graph} />} columns={ColumnsContracts}
                       data={dados.valor_contratos} />
        <TwoChartTable title='Concentração Geográfica' chart={<MapChart data={dados.geografia_map} />} columns={ColumnsStates} data={dados.geografia}
                       mainFlex={2} />
        <OneTable title='Categoria de Produto' columns={ColumnsProducts} data={dados.categorias} />
        <OneTable title='Índices de Correção' columns={ColumnsIndexes} data={dados.indices} />
        <OneTable title='Prazos dos Contratos' columns={ColumnsPrazosContratos} data={dados.prazo_contratos} group={ColumnsGroupPrazosContratos} />
        <TwoChartTable title='Contratos com Parcelas Pagas' chart={<ChartEstContracts data={dados.num_contratos_graph} />} columns={ColumnsParcels}
                       data={dados.num_contratos_est} />
        <OneTable title='Cronograma de Entrega - Habite-se' columns={ColumnsAbertoHabitese} data={dados.aberto_habitese} group={ColumnsGroupAbertoHabitese} />
        <TwoChartTable title='LTV Presente' chart={<ChartLTV data={dados.ltv_presente_graph} />} columns={ColumnsLTV}
                       data={dados.ltv_presente_quartis} />
        <TwoChartTable title='LTV Principal' chart={<ChartLTV data={dados.ltv_principal_graph} />} columns={ColumnsLTV}
                       data={dados.ltv_principal_quartis} />
        <TwoCharts chart1={<ChartNPLHistory data={dados.npl_history_graph} />} chart2={<ChartNPLHistory2 data={dados.npl_history_open_graph} max_y={prov} />} />
        <ChartColumnFlux data={dados.flux_graph} />
        <ChartColumnFluxAccumulated data={dados.flux_graph} />

      </WrapperContent>

      <CustomDrawer
        open={open}
        onClose={handleClose}
        anchor='right'
      >
        <WrapperDrawer>
          <AccordionMui num={1} active={false} title='Upload Carteira' />
          <AccordionMui num={2} active={true} title='Proposta Comercial'
                        data={['Seleção da Carteira', 'Estruturação Sugerida', 'Envio de Proposta Comercial']} />
          <AccordionMui num={3} active={false} title='Análise de Contratos e Documentos'
                        data={['Aprovação dos Contratos de Cessão', 'Due Diligence das Empresas', 'Due Diligence dos Contratos Individuais']} />
          <AccordionMui num={4} active={false} title='Assinatura dos Documentos' />
          <AccordionMui num={5} active={false} title='Envio de Recursos para a Empresa' data={['Seleção de Carteira']} />
        </WrapperDrawer>
      </CustomDrawer>
    </Container>
  );
};

export default AnaliseCarteira;
