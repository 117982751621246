import { Box, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const HeaderTable = styled(Box)`
  background-color: #FFF;
  border-radius: 8px;
`;

export const CustomDataGrid = styled(DataGrid)`
  background-color: #FFF;

.MuiDataGrid-main > .MuiDataGrid-withBorderColor {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.MuiDataGrid-columnHeaderTitle {
 font-weight: 600;
}

border: none;
`;