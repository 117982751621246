import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from '../pages/Home';
import Login from '../pages/Login';
import { Layout } from '../layout';
import CarteiraPre from '../pages/CarteiraPre';
import Parceiros from '../pages/Parceiros';
import Filtros from '../pages/Filtros';
import Configuracao from '../pages/Configuracao';
import CarteiraPos from '../pages/CarteiraPos';
import Dashboard from '../pages/Dashboard';
import AnaliseCarteira from '../pages/AnaliseCarteira';
import StatusFiltros from '../pages/StatusFiltros';
import AnalisePosVenda from '../pages/AnalisePosVenda';

const AppRoutes: React.FC = () => {
  return (
   <BrowserRouter>
    <Routes>
     <Route path='/' element={<Login />}/>
     <Route path='/login' element={<Login />}/>
     <Route path='/home' element={
      <Layout>
       <Home />
      </Layout>
     }/>
     <Route path='/carteira-pre' element={
      <Layout>
       <CarteiraPre />
      </Layout>
     }/>
     <Route path='/parceiros' element={
      <Layout>
       <Parceiros />
      </Layout>
     }/>
     <Route path='/filtros/:partnerId/files/:fileId' element={
      <Layout>
       <Filtros />
      </Layout>
     }/>
     <Route path='/operacoes' element={
      <Layout>
       <CarteiraPos />
      </Layout>
     }/>
     <Route path='/analise-carteira/file/:fileId/filter/:filterId/:filterName' element={
      <Layout>
       <AnaliseCarteira />
      </Layout>
     }/>
     <Route path='/dashboards' element={
      <Layout>
       <Dashboard />
      </Layout>
     }/>
     <Route path='/configuracao' element={
      <Layout>
       <Configuracao />
      </Layout>
     }/>
     <Route path='/status-filtro/:fileId' element={
      <Layout>
       <StatusFiltros />
      </Layout>
     }/>
     <Route path='/analise-pos-venda' element={
      <Layout>
       <AnalisePosVenda />
      </Layout>
     }/>
    </Routes>
   </BrowserRouter>
  );
}

export default AppRoutes;
