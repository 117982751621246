import React, { useEffect, useState } from 'react';

import { Container, HeadModal, ItemHeadPort, ItemPort, ModalContainer, PortTitle, UploadButton } from './styles';
import HeaderTitle from '../../components/HeaderTitle';
import SubHeader from '../../components/SubHeader';
import DataTable from './components/DataTable';
import { useAppSelector } from '../../store';
import { api } from '../../api';
import {v4} from 'uuid';
import { toast } from 'react-toastify';
import { Box, FormControl, InputLabel, MenuItem, Modal, Select, SelectChangeEvent, Typography } from '@mui/material';
import FileUpload from '../../components/FileUpload';
import { FileButtons } from '../CarteiraPre/styles';
import InputData from '../../components/ControlledInputData';
import moment from 'moment';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CarteiraPos = () => {
  const partners = useAppSelector(state => state.partner);
  const [portfolios, setPortFolios] = useState<[]>([]);
  const [portfolio, setPortfolio] = useState<any>({});
  const [files, setFiles] = useState<any>();
  const [uploadable, setUploadable] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const [month, setMonth] = useState<string>('');

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const partnerSelected = partners.find((partner) => partner.selected);

  const loadFilesPos = () => {
    toast.promise(async () => {
      const response = await api.get(`/backend/partners/${partnerSelected?.id}/portfolios`);
      const portfolios = response.data.data.portfolios.filter((port: any) => port.type === 1).sort((a,b) => ('' + a.operation).localeCompare(b.operation));
      setPortFolios(portfolios);
    }, {
      pending: 'Carregando dados da tabela',
      error: 'Não foi possível carregar os dados da tabela',
    }).then();
  };

  const handleChange = (event: any) => {
    const all = event.target.files
    const all_selected = Object.keys(all);
    const arquivos: any[] = [];
    all_selected.forEach((key) => {
      const selected = all[key];
      if (selected.type !== 'text/csv') arquivos.push(selected);
    });
    setFiles(arquivos);
    setUploadable(true)
  }

  const sendUpload = async() => {
    let toastId: any = null;
    try {
      toastId = toast.loading('Realizando Upload', {  });
      setUploadable(false);
      let formData = new FormData();
      files.forEach(a => formData.append("files", a));
      const id_upload = v4();

      await api.post('/backend/portfolios/' + partnerSelected?.id + '/files/post-updates/' + id_upload, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      toast.success("Upload realizado Com Sucesso")
      handleClose()
    } catch(error: any) {
      console.error(error)
      toast.error("Falha no upload");
    } finally {
      toast.dismiss(toastId)
    }
  }

  const handleUpload = async(portfolio: any) => {
    setPortfolio(portfolio)
    handleOpen()
  }

  useEffect(() => {
    if (partnerSelected) {
      loadFilesPos();
    }
  }, [partnerSelected]);

  const handleChangeMonth = (event: SelectChangeEvent) => {
    setMonth(event.target.value as string);
  }

  return (
    <Container>
      <HeaderTitle
        title={`Operações em Andamento - ${partnerSelected?.name}`}
        variant='h4'
        style={{
          marginBottom: 24,
        }}
      />
      <SubHeader
        title='Acompanhe o processamento mensal de suas Operações.'
        style={{
          marginBottom: 64,
        }}
      />

      <Typography
        style={{
          fontWeight: 500,
        }}
        my={4}
        variant='h4'
      >
        Operações
      </Typography>

      {portfolios.map((portfolio: any) => (
        <ItemPort key={portfolio?.id}>
          <ItemHeadPort>
            <PortTitle variant='h5'>
              {portfolio.operation} {portfolio.name ? `(${portfolio.name})` : ''}
            </PortTitle>

            <UploadButton onClick={() => handleUpload(portfolio)}>
              Upload
            </UploadButton>
          </ItemHeadPort>
          <DataTable data={portfolio} />
        </ItemPort>
      ))}

      <Modal
        open={open}
      >
        <ModalContainer>
          <Typography fontWeight="bold" variant='h4' my={2}>
            {portfolio.operation} {portfolio.name ? `(${portfolio.name})` : ''}
          </Typography>
          
          <FormControl fullWidth>
            <InputLabel>Mês</InputLabel>
            <Select
              value={month}
              label="Mês"
              onChange={handleChangeMonth}
            >
              <MenuItem value={`${moment(new Date()).add(1 , 'months').format('YYYY-MM')}`}>
                {`${moment(new Date()).add(1 , 'months').format('MM/YYYY')}`}
              </MenuItem>
              <MenuItem value={`${moment(new Date()).add(2 , 'months').format('YYYY-MM')}`}>
                {`${moment(new Date()).add(2 , 'months').format('MM/YYYY')}`}
                </MenuItem>
              <MenuItem value={`${moment(new Date()).add(3 , 'months').format('YYYY-MM')}`}>
                {`${moment(new Date()).add(3 , 'months').format('MM/YYYY')}`}
              </MenuItem>
            </Select>
          </FormControl>
         

          <FileButtons>
            <FileUpload handler={handleChange}/>
            <UploadButton
              disabled={!uploadable}
              onClick={sendUpload}
            >
              Upload
            </UploadButton>

            <UploadButton onClick={handleClose}>
              Fechar
            </UploadButton>
          </FileButtons>
        </ModalContainer>
      </Modal>
    </Container>
  );
};

export default CarteiraPos;
