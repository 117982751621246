import { Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { Person } from '@mui/icons-material'
import React from 'react';
import { useAppDispatch } from '../../store';
import { logout } from '../../store/user';
import { ButtonLogout, Container } from './styles';
import { clear } from '../../store/slices/partners';

interface ISimplesDialog {
 open: boolean;
 onClose: () => void;
}

interface IUserDialog {
 username: string;
}

const styles: React.CSSProperties = {
  marginRight: 5
}

const SimpleDialog = ({ onClose, open }: ISimplesDialog) => {
 const handleClose = () => onClose();
 const dispatch = useAppDispatch();

 const handleListItemClick = () => {
  dispatch(logout())
  dispatch(clear())
  onClose();
 }

 return (
  <Dialog onClose={handleClose} open={open}>
   <DialogTitle>Configurações Usuário</DialogTitle>
   <List>
    <ListItem key={1}>
     <ListItemButton onClick={handleListItemClick}>
       <ListItemText primary="Logout"/>
      </ListItemButton>
    </ListItem>
   </List>
  </Dialog>
 );
}

const UserDialog = ({ username }: IUserDialog) => {
 const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
   <Container>
    <ButtonLogout variant="text" onClick={handleClickOpen}>
      <Person style={styles}/>
      {username}
    </ButtonLogout>
    <SimpleDialog
      open={open}
      onClose={handleClose}
    />
  </Container>
 );;
}

export default UserDialog;