import React from 'react';

import { ContainerButtons, DeleteButton, EditButton } from './styles';
import { ButtonProps } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';

interface IButtonProps extends ButtonProps {
  handleClick: (action: string) => void;
}

const ActionButton = ({ handleClick,...rest}: IButtonProps) => {
  return (
  <ContainerButtons>  
    <EditButton
      variant='contained' 
      {...rest}
      onClick={() => handleClick('atualizar')}
    >
      <EditNoteIcon />
    </EditButton>
    <DeleteButton
      variant='contained' 
      {...rest}
      onClick={() => handleClick('deletar')}
    >
      <DeleteIcon />
    </DeleteButton>
  </ContainerButtons>
  );
}

export default ActionButton;