import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';

import { useAppSelector } from '../../../../store';
import { useNavigate } from 'react-router-dom';
import { Search } from "@mui/icons-material"
import { CustomDataGrid, HeaderTable, HeaderTableTitle } from '../../stylesMui';


const columns: GridColDef[] = [
  { field: 'original', headerName: 'Nome', width: 400 },
  { field: 'qtd', headerName: 'Qtd. Arquivos', width: 200, sortable: false },
  { field: 'created', headerName: 'Data/Hora', width: 400 },
  { field: 'status', headerName: 'Status', width: 160 },
  {
   field: 'actions',
   headerName: 'Detalhes',
   sortable: false,
   width: 100,
   align: "center",
   renderCell: (params) => {
    return <Search />
   },
  }
];

type IDataTable = {
 data: any;
}

export const DataTable = ({ data }: IDataTable) => {
  const navigate = useNavigate()
  const portfolioId = data[0]?.id

  const handleSelectPartner = (params: any) => {
    const fileId = params.row?.id;
    return navigate(`/filtros/${portfolioId}/files/${fileId}`)
  }

  return (
    <HeaderTable>
      <HeaderTableTitle>Arquivos</HeaderTableTitle>
      {<CustomDataGrid
        rows={data[0]?.files || []}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        rowSelection={false}
        onRowClick={handleSelectPartner}
      />}
    </HeaderTable>
  );
}
