import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../api";
import Cookies from 'universal-cookie';
const cookies = new Cookies();

export const loadPartners = createAsyncThunk('load/Partners', async() => {
 const response = await api.get("/backend/partners")
 return response.data.data
})

interface IPartner {
 id: number;
 name: string;
 type: number;
 selected: boolean;
}

const initialState: IPartner[] = [
 {
  id: 0,
  name: '',
  type: 0,
  selected: false,
 }
]

export const partnerSlice = createSlice({
 name: "partners",
 initialState: initialState,
 reducers: {
  selectedPartner: (state, action: PayloadAction<IPartner[]>) => {
   let expires = new Date();
   expires.setDate(expires.getDate()+30);
   const selected = action.payload.find(x => !!x.selected);
   if (selected) {
    cookies.set('userPartner', selected.name, { path: '/', expires: expires, secure: true });
    cookies.set('userPartnerId', selected.id, { path: '/', expires: expires, secure: true });
   }
   return state = [...action.payload];
  },

  clear: (state) => {
   cookies.remove('userPartner');
   cookies.remove('userPartnerId');
   return state = [...initialState]
  }
 },
 extraReducers(builder) {
  builder.addCase(loadPartners.fulfilled, (state, action) => {
   const data = action.payload.map((part: any) => {
    return {
     ...part,
     selected: false,
    }
   })
   return state = [...data];
  })
 },
})

export const { selectedPartner, clear } = partnerSlice.actions;
export const partner = partnerSlice.reducer;
