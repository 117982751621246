import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { CustomInput } from './styles';
import { Typography } from '@mui/material';

// import { Container } from './styles';

interface IInputText {
 name: string;
 placeholder?: string;
 type?: string;
 label?: string;
 defaultValue?: string;
 step?: string;
}

const InputText = ({ name, placeholder, type, label, defaultValue, step }: IInputText) => {
  const { control } = useFormContext()
  return (
   <Controller 
     name={name}
     control={control}
     defaultValue={defaultValue}
     render={({ field }) => (
      <>
        <Typography>{label}</Typography>
        <CustomInput 
          type={type}
          placeholder={placeholder}
          step={step}
          {...field}
        />
      </>
     )}
   />
  );
}

export default InputText;