import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { CustomDataGrid, Footer, WrapperTable } from './styles';
import { Box, Typography } from '@mui/material';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';

const columns: GridColDef[] = [
  { field: 'parceiro', headerName: 'Parceiro', flex: 1 },
  { field: 'original', headerName: 'Título', flex: 4 },
  { field: 'filtros', headerName: 'Filtros', flex: 1 },
  {
    field: 'created',
    headerName: 'Data Upload',
    flex: 2,
    valueGetter: (params) => `
      ${moment(params.row.created).format('DD/MM/yyyy HH:mm')}
    `,
  },
  { field: 'status', headerName: 'Status', flex: 1 },
  {
    field: 'actions',
    headerName: 'Detalhes',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    renderCell: ({ row }) => {
      return <Box sx={{
        display: 'flex',
        gap: 1
      }}>
      { row.status.substring(0,2) === 'co' && <VisibilityIcon /> }
      </Box>
    },
  },
];

interface IDataTableEnviados {
  data: any[];
}

export const DataTableEnviados = ({ data }: IDataTableEnviados) => {
  const navigate = useNavigate();
  const handleSendingFile = ({ row }) => {
    if (!row || row.status.substring(0,2) !== 'co') return;
    navigate(`/status-filtro/${row.id}`)
  }
  return (
    <WrapperTable>
    {data.length > 0 &&
    <>
        <Typography variant='h5'>
          <b>Carteiras Analisadas</b>
        </Typography>
        <CustomDataGrid
          rows={data}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 40 },
            },
          }}
          pageSizeOptions={[10,40]}
          rowSelection={false}
          onRowClick={handleSendingFile}
        />
      </>}
    </WrapperTable>
  );
}
