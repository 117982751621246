import { GridColDef, GridColumnGroupingModel } from '@mui/x-data-grid';
import { parseInt, parseRS } from '../helpers';

export const ColumnsAbertoHabitese: GridColDef[] = [
  { field: 'semester', headerName: 'Semestre', flex: 1 },
  { field: 'qty_post', headerName: '# Contratos', flex: 1 },
  { field: 'val_beforehand_post', headerName: 'Valor residual (antecipação)', flex: 2 },
  { field: 'val_nominal_post', headerName: 'Valor residual (nominal)', flex: 2 },
  { field: 'qty', headerName: '# Contratos', flex: 1 },
  { field: 'val_beforehand', headerName: 'Valor residual (antecipação)', flex: 2 },
  { field: 'val_nominal', headerName: 'Valor residual (nominal)', flex: 2 },
];

export const ColumnsTotalHabitese: GridColDef[] = [
  { field: 'semester', headerName: 'Semestre', flex: 1 },
  { field: 'qty', headerName: '# Contratos', flex: 1 },
  { field: 'val_beforehand', headerName: 'Valor residual (antecipação)', flex: 2 },
  { field: 'val_nominal', headerName: 'Valor residual (nominal)', flex: 2 },
];

export const ColumnsGroupAbertoHabitese: GridColumnGroupingModel = [
  {
    groupId: 'Em aberto Pós habite-se',
    children: [{ field: 'qty_post' }, { field: 'val_beforehand_post'}, { field: 'val_nominal_post'}],
  },
  {
    groupId: 'Em aberto Pré habite-se',
    children: [{ field: 'qty' }, { field: 'val_beforehand'}, { field: 'val_nominal'}],
  },
];

export const DataAbertoHabitese = [
    {
      id: 0,
      semester: '2024 S1',
      qty_post: 18,
      val_beforehand_post: 18345332,
      val_nominal_post: 21787901,
      qty: 18,
      val_beforehand: 4789678,
      val_nominal: 4789678,
    },
  {
    id: 1,
    semester: 'Total',
    qty_post: 18,
    val_beforehand_post: 18345332,
    val_nominal_post: 21787901,
    qty: 18,
    val_beforehand: 4789678,
    val_nominal: 4789678,
  },
  ];
