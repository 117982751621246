import { configureStore } from '@reduxjs/toolkit';
import { user } from './user';
import { useSelector, TypedUseSelectorHook, useDispatch } from 'react-redux';
import { partner } from './slices/partners';

export const store = configureStore({
 reducer: {
  user: user,
  partner: partner
 }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => AppDispatch = useDispatch