import React from 'react';

import { Container, CustomLink } from './styles';

interface ILinks {
 to: string;
 label: string;
}

const Links = ({ label, to }: ILinks) => {
  return (
   <Container>
    <CustomLink 
     to={to}
    >
     {label}
    </CustomLink>
   </Container>
  );
}

export default Links;