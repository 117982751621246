import { AppBar, Box, Drawer, List, ListItemButton, Toolbar, styled } from "@mui/material";
import ListItemText from '@mui/material/ListItemText';

export const CustomDrawer = styled(Drawer)`
 & .MuiDrawer-paper {
  width: 220px;
  background-color: #000000;
  box-sizing: border-box;
  padding-top: 5rem;
  height: 100vh;
 }
  
 width: 195px;

 @media print {
  display: none;
 }
`;

export const CustomAppBar = styled(AppBar)`
 background-color: #FFF;
 color: #000;
 height: 90px;
 display: flex;
 flex: 1;
 flex-direction: row;
 justify-content: space-between;
 align-items: center;
 padding: 0 5rem;
`;

export const BoxLinks = styled(Box)`
 display: flex;
 flex-direction: row;
 gap: 1rem;
`;

export const CustomListItemButton = styled(ListItemButton)`
 display: flex;
 flex-direction: column;
`;

export const AsideMenuBox = styled(Box)`
 display: flex;
 flex: 1;
 flex-direction: column;
 justify-content: space-between;
 padding: 0;
`

export const CustomList = styled(List)`
 display: flex;
 flex-direction: column;
 gap: 1rem;
`;

export const CustomListItemText = styled(ListItemText)`
  text-align: center;
`
