import moment from "moment";
import {increaseData} from ".";

const validateIndex = (lines) => {
  const dataVencimento = lines.datavencimento
  const dataHabitese = lines.datahabitese
  try {
    if (lines.indexador === "INCC") {
      return moment(dataVencimento).isAfter(dataHabitese);
    }
    return false;
  } catch (e) {
    return false;
  }
}

export function validateFamily7(lines) {
  const erros = {total: 0, legendas: {}};
  lines.forEach((l, n) => {
    if (l.empty) return;
    let chave = 'idvenda_' + l.id_venda
    let validaTaxaJuros = validateIndex(l)
    if (validaTaxaJuros) increaseData(erros, chave, "Erro_700", (n + 2), l.id_venda, 'Índice INCC com data de vencimento maior que data do habite-se');
  })
  return erros;
}
