import styled from "styled-components";

export const Container = styled.div`

`;

export const InputDataList = styled.input`
 width: 100%;
 border: 1px solid #000;
 border-radius: 8px;
 padding: 1rem;
 height: 76px;
`;

export const CustomDataList = styled.datalist`
 width: 100%;
 border: 1px solid #000;
 border-radius: 8px;
 padding: 1rem;
 height: 76px;
`;