import { Link } from "react-router-dom";
import styled from "styled-components";

export const Container = styled.div`
 margin-bottom: 1rem;
`;

export const CustomLink = styled(Link)`
 display: flex;
 width: 500px;
 text-decoration: none;
 padding: .5rem 1rem;
 border-radius: 8px;
 box-shadow: 3px 3px 7px -1px rgba(0,0,0,0.44);
 background-color: #FAFAFA;
 color: #000;
`;