import React from 'react';

import { Title } from './styles';
import { TypographyProps } from '@mui/material';

interface IHeaderTitle extends TypographyProps {
 title: string;
}

const SubHeader = ({ title, variant, ...rest }: IHeaderTitle) => {
  return (
   <Title variant={variant}{...rest}>
    {title}
   </Title>
  );
}

export default SubHeader;