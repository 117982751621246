import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '.';
import { toast } from 'react-toastify';
import { AuthenticationDetails, CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

let cogUser: any = null;

interface IUser {
  id: number,
  email: string,
  name: string,
  admin: boolean,
  partner: {
    id: number,
    name: string,
    type: number,
    dashboard1: null,
    dashboard2: null,
    portfolios: [],
  }
  loading: boolean;
  token: string;
  newPassword: boolean;
}

const initialState: IUser = {
  id: 0,
  email: '',
  name: '',
  admin: false,
  partner: {
    id: 0,
    name: '',
    type: 0,
    dashboard1: null,
    dashboard2: null,
    portfolios: [],
  },
  loading: false,
  token: '',
  newPassword: false,
};

interface IResponseLogin {
  email: string;
  password: string;
}


export const newPasswordRequest = createAsyncThunk('login/NewPassword', async ({ password }: any) => {
  return await new Promise<any>((resolve, reject) => {
    cogUser.completeNewPasswordChallenge(password, [], {
      onSuccess: (result) => {
        const jwt = result.getIdToken().getJwtToken();
        const decodedUser = result.getIdToken().decodePayload();
        resolve({
          jwt, decodedUser
        });
      },
      onFailure: reject,
    });
  });
});


export const loginUser = createAsyncThunk('login/User', async ({ email, password }: IResponseLogin) => {
  const authenticationData = {
    Username : email,
    Password : password
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);
  const poolData = {
    UserPoolId : 'us-west-2_jUJbLCuFw',
    ClientId : '2ulr8ee7i3p770l4ag5c6lgl3r'
  };
  const userPool = new CognitoUserPool(poolData);
  const userData = {
    Username : email,
    Pool : userPool
  };
  cogUser = new CognitoUser(userData);

  return await new Promise<any>((resolve, reject) => {
    cogUser.authenticateUser(authenticationDetails, {
      onSuccess: (result) => {
        const jwt = result.getIdToken().getJwtToken();
        const decodedUser = result.getIdToken().decodePayload();
        resolve({
          jwt, decodedUser
        });
      },
      newPasswordRequired: (userAtts, reqAtts) => {
        resolve({ type: 'newPasswordRequired' });
      },
      onFailure: reject,
    });
  });
});

const fulfillLogin = (state, action: PayloadAction<any>) => {
  if (action.payload.type === 'newPasswordRequired') {
    // newPasswordRequest
    return state = { ...state, newPassword: true, loading: false }
  } else {
    const token: any = action.payload.decodedUser; //jwtDecode(action.payload);
    const parsedUserToken = JSON.stringify(token);
    localStorage.setItem('@user', parsedUserToken);
    localStorage.setItem('@token', action.payload.jwt);

    localStorage.setItem("@user:name", token.name)
    localStorage.setItem("@user:email", token.email)
    localStorage.setItem("@user:admin", String(token['custom:admin'] == 'true'))
    localStorage.setItem("@user:partner", token['custom:partner'])

    return state = {
      ...action.payload,
      newPassword: false,
      name: token.name,
      email: token.email,
      admin: token['custom:admin'] == 'true',
      partner: token['custom:partner'],
      token: action.payload,
      loading: false
    }
  };
}

export const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('@token');
      localStorage.removeItem('@user');
      localStorage.removeItem("@user:name")
      localStorage.removeItem("@user:email")
      localStorage.removeItem("@user:admin")
      localStorage.removeItem("@user:partner")
      return state = { ...initialState };
    },
    closeModalNewPassword: (state) => {
      return state = {...state, newPassword: false}
    }
  },
  extraReducers(builder) {
    builder.addCase(loginUser.pending, (state) => {
      return state = { ...state, loading: true };
    });
    builder.addCase(newPasswordRequest.pending, (state) => {
      return state = { ...state, loading: true };
    });

    builder.addCase(loginUser.fulfilled, fulfillLogin);
    builder.addCase(newPasswordRequest.fulfilled, fulfillLogin);

    builder.addCase(loginUser.rejected, (state) => {
      toast.error("Falha no login");
      return state = { ...state, loading: false };
    });

    builder.addCase(newPasswordRequest.rejected, (state) => {
      toast.error('Nova Senha inválida');
      return state = { ...state, loading: false };
    });
  },
});

export const user = userSlice.reducer;
export const loadingAuth = (state: RootState) => state.user.loading;
export const { logout, closeModalNewPassword } = userSlice.actions;
