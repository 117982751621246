import moment from "moment";
import { increaseData } from ".";

export const isNumber = (n) => {
  const regexDecimais = /^\d+([.,]\d+)?$/;
  return regexDecimais.test(n);
}

export const isValidDate = (d) => {
  if (!d) return { value: null, isNull: true, isValid: true };
  if (d.toString().trim() === '') return { value: null, isNull: true, isValid: true };
  const d2 = d.toString().trim().toLowerCase();
  if (['-', 'ni', 'na', 'n/a'].some(x => x === d2)) return { value: null, isNull: true, isValid: true };
  const valid1 = moment(d2, 'YYYYMMDD').isValid()
  if (valid1) return { value: valid1, isNull: false, isValid: true };
  const valid2 = moment(d2, 'DD/MM/YYYY').isValid()
  if (valid2) return { value: valid2, isNull: false, isValid: true };
  const valid3 = moment(d2, 'DD-MM-YYYY').isValid();
  if (valid3) return { value: valid3, isNull: false, isValid: true };
  else return { value: null, isNull: false, isValid: false };
}

export function validateFamily1(lines) {
  const erros = { total: 0, legendas: {} };
  lines.forEach((l, n) => {
    if (l.empty) return;
    let chave = 'idvenda_' + l.id_venda
    let validaVenda = isNumber(l.valorvenda);
    let validaValorNominalParcela = isNumber(l.valornominaldasparcelas);
    let validaValorParcelaPrincipal = isNumber(l.valorparcelaprincipal);
    let validaValorAntecipacao = isNumber(l.valorparaantecipao || l.valorparaantecipacao);
    let validaValorRecebido = isNumber(l.valorrecebido);
    let validaValorTaxaJuros = isNumber(l.taxajuros);

    //verify dates
    let validaDataHabitese = isValidDate(l.datahabitese);
    let validaDataVenda = isValidDate(l.datavenda);
    let validaDataVencimento = isValidDate(l.datavencimento);
    let validaDataRecebimento = isValidDate(l.datarecebimento);

    if (!validaVenda) increaseData(erros, chave, "Erro_100", (n+2), l.id_venda, 'Valor venda em formato não reconhecido');
    if (!validaValorNominalParcela) increaseData(erros, chave, "Erro_101", (n+2), l.id_venda, 'Valor de parcela nominal em formato não reconhecido');
    if (!validaValorParcelaPrincipal) increaseData(erros, chave, "Erro_102", (n+2), l.id_venda, 'Valor de parcela principal em formato não reconhecido');
    if (!validaValorAntecipacao) increaseData(erros, chave, "Erro_103", (n+2), l.id_venda, 'Valor de antecipação em formato não reconhecido');
    if (!validaValorRecebido) increaseData(erros, chave, "Erro_104", (n+2), l.id_venda, 'Valor recebido em formato não reconhecido');
    if (!validaValorTaxaJuros) increaseData(erros, chave, "Erro_105", (n+2), l.id_venda, 'Valor de taxa de juros em formato não reconhecido');

    if (!validaDataHabitese.isValid) increaseData(erros, chave, "Erro_106", (n+2), l.id_venda, 'Data de Habite-se em formato não reconhecido');
    if (!validaDataVenda.isValid) increaseData(erros, chave, "Erro_107", (n+2), l.id_venda, 'Data de venda em formato não reconhecido');
    if (!validaDataVencimento.isValid) increaseData(erros, chave, "Erro_108", (n+2), l.id_venda, 'Data de vencimento em formato não reconhecido');
    if (!validaDataRecebimento.isValid) increaseData(erros, chave, "Erro_109", (n+2), l.id_venda, 'Data de recebimento em formato não reconhecido');

  })
  return erros;
}
