import { Box, Button, styled } from "@mui/material";

export const ContainerButtons = styled(Box)`
 display: flex;
 gap: 0.5rem;
`;

export const EditButton = styled(Button)`
 text-transform: none;
 background-color: #F5766C;
 border: 1px solid #F5766C;

 &:hover {
  transition: 0.3s;
  background-color: #FAFAFA;
  color: #F5766C;
 }
`;

export const DeleteButton = styled(Button)`
 text-transform: none;
 background-color: #F5766C;
 border: 1px solid #F5766C;

 &:hover {
  transition: 0.3s;
  background-color: #FAFAFA;
  color: #F5766C;
 }
`;