import { Box, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

interface IBarProgressProps {
  progress: number;
}

export const WrapperTable = styled(Box)`
  background-color: #FAFAFA;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  margin-bottom: 5rem;
  max-width: 99%;
`;

export const CustomDataGrid = styled(DataGrid)`
  background-color: #FAFAFA;

.MuiDataGrid-main > .MuiDataGrid-withBorderColor {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.MuiDataGrid-columnHeaderTitle {
 font-weight: 600;
}

.MuiDataGrid-footerContainer {
  border-bottom: 2px solid #000;
}

border: none;
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const ContainerProgress = styled(Box)`
  display: flex;
  width: 5rem;
  height: 1.5rem;
  border: 1px solid #000;
  border-radius: 8px;
  overflow: hidden;
`;

export const BarProgress = styled(Box)<IBarProgressProps>`
  background-color: #32E875;
  width: ${({ progress }) => progress}%;
`;
