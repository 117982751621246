import * as React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { CustomDataGrid, Footer, WrapperTable } from './styles';
import { Box, Button, Typography } from '@mui/material';
import moment from 'moment';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate, useParams } from 'react-router-dom';

import { ContainerProgress, BarProgress } from './styles';
import StatusFilterBox from './components/StatusFilterBox';
import ActionButton from './components/ActionButton';
import { toast } from 'react-toastify';

const columns: (
  handleSendingFile: (params: any) => void, 
  handleManipulateFilter: (action: string, data: any) => void) => GridColDef[] = 
  (handleSendingFile, handleManipulateFilter) => 
  [
    { 
      field: 'name', 
      headerName: 'Nome', 
      flex: 1,
      renderCell: ({ row }) => row.name === null ? 'Sem nome' : `${row.name}` 
    },
    {
      field: 'created',
      headerName: 'Criado',
      flex: 1,
      valueGetter: (params) => `
        ${moment(params.row.created).format("DD/MM/yyyy HH:MM")}
      `,
    },
    { 
      field: 'status', 
      headerName: 'Status', 
      flex: 1,
      headerAlign: 'left',
      align: 'left',
      renderCell: ({ row }) => <StatusFilterBox row={row}/> 
    },
    { 
      field: 'processing', 
      headerName: 'Processando', 
      flex: 1, 
      align: 'center', 
      headerAlign: 'center',
      renderCell: ({ row }) => (progressBar(row.processing)) 
    },
    {
      field: 'actions',
      headerName: 'Detalhes',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Button
            variant='text' 
            sx={{
              cursor: 'pointer',
              color: "#000"
            }}
            onClick={() => handleSendingFile(row)}
          >
            { <VisibilityIcon /> }
          </Button>
        )
      },
    },
    {
      field: 'actions2',
      headerName: 'Ação',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <ActionButton handleClick={(action) => handleManipulateFilter(action, row)}/>
        )
      }
    },
  ];

const progressBar = (progress: number) => {
  return (
    <ContainerProgress>
      <BarProgress progress={progress}/>
    </ContainerProgress>
  )
}

interface IDataTableFiltrosStatus {
  data: any[];
  handleSaveFiltro: (data: any) => void;
  handleDeleteFilter: (data: any) => void;
}

export const DataTableFiltrosStatus = ({ data, handleSaveFiltro, handleDeleteFilter }: IDataTableFiltrosStatus) => {
  const navigate = useNavigate();
  const { fileId } = useParams();
  const handleSendingFile = (row) => {
    if(Number(row.processing) < 100){
      toast.warning("Filtro ainda esta sendo processado")
    } else {
      navigate(`/analise-carteira/file/${fileId}/filter/${row.id}/${row.name}`)
    }
  }

  const handleManipulateFilter = (action:string, row: any) => {
    const options = {
      'atualizar': () => handleSaveFiltro(row),
      'deletar': () => handleDeleteFilter(row)
    }
    return options[action](row);
  }

  return (
    <WrapperTable>
      <CustomDataGrid
        rows={data}
        columns={columns(handleSendingFile, handleManipulateFilter)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 40 },
          },
        }}
        pageSizeOptions={[10,40]}
        rowSelection={false}
      />
    </WrapperTable>
  );
}
