import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, HeaderTable } from './styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import { Typography } from '@mui/material';
import { parseDate } from '../../../AnaliseCarteira/datasets/helpers';

const columns: GridColDef[] = [
  { field: 'ano_mes', headerName: 'Ano-mês', flex: 1 },
  { field: 'esperado_receber_mes', headerName: 'Esperado a Receber no Mês', flex: 2 },
  { field: 'recebido_mes', headerName: 'Recebido Mês', flex: 1 },
  { field: 'recebido_mes_antecipacao', headerName: 'Recebido no Mês Antecipação', flex: 1 },
  { field: 'recebido_mes_parcela_mes', headerName: 'Recebido no mês de parcela do mês', flex: 1 },
  { field: 'recebido_mes_inadimplente', headerName: 'Recebido no mês inadimplente', flex: 1 },
]

interface IDataTable {
 data: Array<any>;
}

const dataLocal = [
  {
    id: 1,
    ano_mes: "2024-05",
    esperado_receber_mes: "R$ 1.095.786,00",
    recebido_mes: "R$ 500.000,00",
    recebido_mes_antecipacao: "R$ 75.000,00",
    recebido_mes_parcela_mes: "R$ 352.987,54",
    recebido_mes_inadimplente: "R$ 88.621.879,53"
  }
]

const DataTable = ({ data }: any) => {
 const navigate = useNavigate();
  const handleSelectItem = (params: any) => {
   // navigate({
   //  pathname: "/file-pos",
   //  search: createSearchParams({
   //   data: params.row,
   //  }).toString()
   // })
  }
  return (
   <WrapperTable>
     <HeaderTable>
        Fluxo de Caixa
      </HeaderTable>
       <CustomDataGrid
        rows={dataLocal}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        rowSelection={false}
        onRowClick={handleSelectItem}
      />
    </WrapperTable>
  );
}

export default DataTable;
