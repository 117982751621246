import { GridColDef, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import { CustomDataGrid, HeaderTable } from './styles';


const columns: GridColDef[] = [
  { field: 'id_venda', headerName: 'Contrato', flex: 1 },
];

type IDataTable = {
 data: any;
}

function csvToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport csvOptions={{ delimiter: ';', utf8WithBom: true }} />
    </GridToolbarContainer>
  );
}

export const DataTable = ({ data }: IDataTable) => {
  if (!data.colunas) return <div></div>

  data.colunas.forEach(c => columns.push(c));
  // columns.push({ field: 'linhas', headerName: 'Linhas do Arquivo', flex: 2 })
  return (
    <HeaderTable>
      {<CustomDataGrid
        rows={data.erros}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 20 },
          },
        }}
        slots={{ toolbar: csvToolbar }}
        pageSizeOptions={[10, 20, 50]}
        rowSelection={false}
        onRowClick={() => {}}
        getRowId={(row) => row.id_venda}
      />}
    </HeaderTable>
  );
}
