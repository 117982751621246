import { increaseData } from ".";

export function validateFamily5(lines) {
 const erros = { total: 0, legendas: {} };
 lines.forEach((l, n) => {
   if (l.empty) return;
   let chave = 'idvenda_' + l.id_venda

   const cod_empresa = l.cdigodaempresa || l.codigodaempresa;
   const cod_obra = l.cdigodaobra || l.codigodaobra;
   const al_fiduciaria = l.alienaofiduciria || l.alienacaofiduciaria || l.alienacaofiduciria;
   const status_cobranca = l.statuscobrana || l.statuscobranca;
   const antecipacao = l.valorparaantecipao || l.valorparaantecipacao;

   if(!l.id_venda || l.id_venda.trim() === "") increaseData(erros, chave, "Erro_501", (n+2), l.id_venda, 'Id de venda inválido');
   if(!l.tipocliente || l.tipocliente.trim() === "") increaseData(erros, chave, "Erro_502", (n+2), l.id_venda, 'Tipo de cliente inválido');
   if(!cod_empresa || cod_empresa.trim() === "") increaseData(erros, chave, "Erro_503", (n+2), l.id_venda, 'Código da empresa inválido');
   if(!cod_obra || cod_obra.trim() === "") increaseData(erros, chave, "Erro_504", (n+2), l.id_venda, 'Código da obra inválido');
   if(!l.ufempreendimento || l.ufempreendimento.trim() === "") increaseData(erros, chave, "Erro_505", (n+2), l.id_venda, 'UF empreedimento inválido');
   if(!l.datahabitese || l.datahabitese.trim() === "") increaseData(erros, chave, "Erro_506", (n+2), l.id_venda, 'Data habite-se inválido');
   if(!l.datavenda || l.datavenda.trim() === "") increaseData(erros, chave, "Erro_507", (n+2), l.id_venda, 'Data de venda inválido');
   if(!l.tipovenda || l.tipovenda.trim() === "") increaseData(erros, chave, "Erro_508", (n+2), l.id_venda, 'Tipo de venda inválido');
   if(!al_fiduciaria || al_fiduciaria.trim() === "") increaseData(erros, chave, "Erro_509", (n+2), l.id_venda, 'Alienação fiduciária inválida');
   if(!l.categoriaproduto || l.categoriaproduto.trim() === "") increaseData(erros, chave, "Erro_510", (n+2), l.id_venda, 'Categoria de produto inválida');
   if(!l.valorvenda || l.valorvenda.trim() === "" || l.valorvenda === '0') increaseData(erros, chave, "Erro_511", (n+2), l.id_venda, 'Valor de venda inválida');
   if(!status_cobranca || status_cobranca.trim() === "") increaseData(erros, chave, "Erro_512", (n+2), l.id_venda, 'Status de cobrança inválida');
   if(!l.indexador || l.indexador.trim() === "") increaseData(erros, chave, "Erro_513", (n+2), l.id_venda, 'Indexador inválido');
   // Erro 514 é suficientemente tratado pelo 300
   if(!l.datavencimento || l.datavencimento.trim() === "") increaseData(erros, chave, "Erro_515", (n+2), l.id_venda, 'Data de vencimento inválida');
   if(!l.valornominaldasparcelas || l.valornominaldasparcelas.trim() === "" || l.valornominaldasparcelas === '0') increaseData(erros, chave, "Erro_516", (n+2), l.id_venda, 'Valor nominal da parcela inválida');
   if(!l.valorparcelaprincipal || l.valorparcelaprincipal.trim() === "" || l.valorparcelaprincipal === '0') increaseData(erros, chave, "Erro_517", (n+2), l.id_venda, 'Valor da parcela principal inválida');
   if(!antecipacao || antecipacao.trim() === "" || antecipacao === '0') increaseData(erros, chave, "Erro_518", (n+2), l.id_venda, 'Valor para antecipação inválido');
 })
 return erros;
}
