import { GridColDef } from '@mui/x-data-grid';
import { parseRS } from '../helpers';



export const ColumnsContracts: GridColDef[] = [
  { field: 'qty', headerName: 'Valores', flex: 1, align: 'center', headerAlign: 'center' },
  { field: 'bucket_origin', headerName: 'Carteira Originada', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseRS },
  { field: 'bucket', headerName: 'Carteira', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseRS },
];

export const ColumnsParcels: GridColDef[] = [
  { field: 'qty', headerName: 'Valores', flex: 1, align: 'center', headerAlign: 'center' },
  { field: 'bucket', headerName: '# Parcelas Pagas', flex: 1, align: 'center', headerAlign: 'center' },
];

export const DataContracts = [
    {
      id: 0,
      qty: 'Mínimo',
      bucket_origin: 58000,
      bucket: 6510,
    },
    {
      id: 1,
      qty: 'Primeiro Quartil',
      bucket_origin: 78900,
      bucket: 8510,
    },
    {
      id: 2,
      qty: 'Mediana',
      bucket_origin: 97000,
      bucket: 9910,
    },
    {
      id: 3,
      qty: 'Média',
      bucket_origin: 97000,
      bucket: 9910,
    },
    {
      id: 4,
      qty: 'Terceiro Quartil',
      bucket_origin: 168000,
      bucket: 34310,
    },
    {
      id: 5,
      qty: 'Máximo',
      bucket_origin: 248000,
      bucket: 55510,
    },
  ];
