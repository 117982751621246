import { Box, Button, styled } from "@mui/material";

export const Container = styled(Box)`
 display: flex;
 flex-direction: column;
 padding: 7rem 0 0 3rem;
`;

export const SubmitButton = styled(Button)`
 background-color: #F46F62;
 border: 1px solid #F46F62;
 padding: 0.5rem 2rem;
 border-radius: 30px;
 color: #FFF;
 transition: .3s;
 cursor: pointer;
 width: 200px;

 &:hover {
  border: 1px solid #F46F62;
  background-color: #FFF;
  color: #F46F62
 }
 `;