import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)`
 display: flex;
 flex: 1;
 flex-direction: column;
 border: 1px solid #D9D9D9;
 border-radius: 8px;
 box-shadow: 1px 3px 3px rgba(0 , 0, 0, 0.25);
 cursor: pointer;
 height: 12rem;

 &:hover {
  transition: 0.3s;
  border-color: #000;
  background-color: #F46F62;
 }
`;

export const Header = styled(Box)`
 display: flex;
 flex: 1;
 justify-content: flex-start;
 align-items: center;
 padding: 1rem;
`;

export const Content = styled(Box)`
 display: flex;
 flex: 1;
 justify-content: flex-start;
 align-items: center;
 padding: 1rem;
`;

export const Title = styled(Typography)`
 font-weight: bold;
`;

export const Description = styled(Typography)`
 max-width: 15rem;
 font-size: 1.5rem;
`;