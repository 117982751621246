import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
display: flex;
flex-direction: column;
padding: 1rem;
margin-bottom: 5rem;
`;

export const ChartDetails = styled(Box)`
 
`;

export const ChartMain = styled(Box)`

`;