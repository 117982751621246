/* eslint-disable no-template-curly-in-string */
import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Container } from './styles';
import { IGraph } from '../../datasetInterface';

interface IInput {
  data: IGraph & { y2: any[] }
  max_y: number;
}

const ChartNPLHistory2 = (input: IInput) => {
 const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options =  {
    title: {
      text: 'Contratos Ativos e Originados'
    },
    xAxis: {
      crosshair: true,
      accessibility: {
        description: 'Mês/Ano'
      },
      categories: input.data?.x,
    },
    yAxis: {
      min: 0,
      title: {
        text: "# Contratos"
      },
    },
    series: [{
      type: 'column',
      name: "Contratos Originados",
      data: input.data?.y2,
      color: "#1D1165",

    },{
      type: 'line',
      name: "Contratos Ativos",
      data: input.data?.y,
      color: "#F46F62",
    }]
  };
  return (
   <Container>
     <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
   </Container>
  );
}

export default ChartNPLHistory2;
