import {increaseData} from ".";

function validaCPF(cpf) {
  if (cpf === '' || !cpf) return false;
  cpf = cpf.replace(/\D+/g, '');
  // Elimina CPFs invalidos conhecidos
  if (cpf.length !== 11 || cpf === "00000000000" || cpf === "11111111111" || cpf === "22222222222" || cpf === "33333333333" || cpf === "44444444444" || cpf === "55555555555" || cpf === "66666666666" || cpf === "77777777777" || cpf === "88888888888" || cpf === "99999999999") {
    return false;
  }
  // Valida 1o digito
  var add = 0;
  for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
  var rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  if (rev !== parseInt(cpf.charAt(9))) {
    return false;
  }
  // Valida 2o digito
  add = 0;
  for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) rev = 0;
  return rev === parseInt(cpf.charAt(10), 10);

}

function validaCNPJ(cnpj) {

  if (cnpj === '' || !cnpj) return false;

  cnpj = cnpj.replace(/\D+/g, '');
  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (cnpj === "00000000000000" || cnpj === "11111111111111" || cnpj === "22222222222222" || cnpj === "33333333333333" || cnpj === "44444444444444" || cnpj === "55555555555555" || cnpj === "66666666666666" || cnpj === "77777777777777" || cnpj === "88888888888888" || cnpj === "99999999999999") return false;

  // Valida DVs
  var b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
  var c = String(cnpj).replace(/\D/g, '')

  if (c.length !== 14) return false

  if (/0{14}/.test(c)) return false

  for (var i = 0, n = 0; i < 12; n += c[i] * b[++i]) ;
  if (c[12] != (((n %= 11) < 2) ? 0 : 11 - n)) return false

  for (var i = 0, n = 0; i <= 12; n += c[i] * b[i++]) ;
  if (c[13] != (((n %= 11) < 2) ? 0 : 11 - n)) return false

  return true
}

export function validaCabecalho(linha1) {
  const necessarios = ['id_venda', 'cpfcnpj', 'tipocliente', 'contratocedido', 'cdigodaempresa', 'cdigodaobra', 'ufempreendimento',
    'datahabitese', 'datavenda', 'tipovenda', 'alienaofiduciria', 'categoriaproduto', 'valorvenda', 'statuscobrana', 'indexador', 'datavencimento',
    'datarecebimento', 'valornominaldasparcelas', 'valorparcelaprincipal', 'valorparaantecipao']
  const necessarios2 = ['id_venda', 'cpfcnpj', 'tipocliente', 'contratocedido', 'codigodaempresa', 'codigodaobra', 'ufempreendimento',
    'datahabitese', 'datavenda', 'tipovenda', 'alienacaofiduciaria', 'categoriaproduto', 'valorvenda', 'statuscobranca', 'indexador', 'datavencimento',
    'datarecebimento', 'valornominaldasparcelas', 'valorparcelaprincipal', 'valorparaantecipacao']
  const nomes = ['ID_Venda', 'CPF/CNPJ', 'Tipo Cliente', 'Contrato Cedido', 'Código da Empresa', 'Código da Obra', 'UF Empreendimento',
    'Data Habite-se', 'Data Venda', 'Tipo Venda', 'Alienação Fiduciária', 'Categoria Produto', 'Valor Venda', 'Status Cobrança', 'Indexador', 'Data Vencimento',
    'Data Recebimento', 'Valor Nominal das Parcelas', 'Valor Parcela Principal', 'Valor para Antecipação']
  const erros = [];
  necessarios.forEach((c, idx) => {
    const teste = (x) => {
      const parsed_header = x.toLowerCase().replace(/\W/g, '');
      return parsed_header === c;
    };
    if (!linha1.some(teste)) erros.push(nomes[idx]);
  })
  const erros2 = [];
  necessarios2.forEach((c, idx) => {
    const teste = (x) => {
      const parsed_header = x.toLowerCase().replace(/\W/g, '');
      return parsed_header === c;
    };
    if (!linha1.some(teste)) erros2.push(nomes[idx]);
  })
  const comum = erros.filter(value => erros2.includes(value));
  /*if (comum.some(x => x === 'Valor Nominal das Parcelas')) {
    if (!linha1.valorfuturodasparcelas) return comum;
    else return comum.filter(x => x !== 'Valor Nominal das Parcelas');
  }*/
  return comum;
}

export function validateFamily0(lines) {
  const erros = {total: 0, legendas: {}};
  lines.forEach((l, n) => {
    if (l.empty) return;
    let chave = 'idvenda_' + l.id_venda
    var valido = 0;
    if (l.tipocliente) {
      if (l.tipocliente.toLowerCase() === 'pf') valido = validaCPF(l.cpfcnpj) ? 0 : 1; else if (l.tipocliente.toLowerCase() === 'pj') valido = validaCNPJ(l.cpfcnpj) ? 0 : 2; else {
        increaseData(erros, chave, "Erro_002", (n + 2), l.id_venda, 'CPF/CNPJ não conforma com Tipo Cliente')
      }
    } else {
      increaseData(erros, chave, "Erro_002", (n + 2), l.id_venda, 'CPF/CNPJ não conforma com Tipo Cliente')
    }
    if (valido === 1) increaseData(erros, chave, "Erro_001", (n + 2), l.id_venda, 'CPF/CNPJ inválido');
    else if (valido === 2) increaseData(erros, chave, "Erro_001", (n + 2), l.id_venda, 'CPF/CNPJ inválido');
  })
  return erros;
}
