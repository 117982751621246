import styled from "styled-components";

export const Container = styled.div`
 display: grid;
 grid-template-columns: 4fr 2fr;
 height: 100vh;
 grid-column-gap: 1rem;

 @media(min-width: 3300px){
  grid-template-columns: 5fr 2fr;
 }

 @media(max-width: 1750px){
  grid-template-columns: 1fr;
 }
`;

export const Main = styled.div`
 padding: 7rem 0 0 3rem;
 display: flex;
 flex-direction: column;
`;

export const Aside = styled.div`
 display: flex;
 flex-direction: column;
 gap: 1rem;
 background-color: #FAFAFA;
 padding: 7rem 1rem;
 flex: 1;

 @media(max-width: 1750px){
  display: none;
 } 
`;

export const ContainerWrapper = styled.div`
 max-width: 1100px;
`;

export const FileButtons = styled.div`
 display: flex;
 flex: 1;
 justify-content: flex-start;
 align-items: center;
 height: 50px;
 padding: 3rem 0;
 gap: 1rem;
 width: 100%;
`;