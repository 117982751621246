import { Box, styled } from "@mui/material";

const statusColor = {
 "publicado": "green",
 "salvo": "#357DED",
 "oculto": "red",
 "processando": "#FFC914",
} as const

interface IContainerProps {
 status: string;
} 

export const Container = styled(Box)<IContainerProps>`
 display: flex;
 padding: 0.3rem 1rem;
 border: 1px solid ${({ status }) => statusColor[status]};
 color: ${({ status }) => statusColor[status]};
 border-radius: 8px;
`;