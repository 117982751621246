import React from 'react';

import { CustomCard, Title, SubTitle } from './styles';
import { Box, CardContent } from '@mui/material';

const bull = (
 <Box
   component="span"
   sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
 >
   •
 </Box>
);

const card = (
 <React.Fragment>
   <CardContent>
     <Title variant='body1'>
       Envios
     </Title>
     <SubTitle variant="h4">
       156
     </SubTitle>
   </CardContent>
 </React.Fragment>
);

const Card = () => {
  return (
   <CustomCard variant='outlined'>
     {card}
   </CustomCard>
  );
}

export default Card;