import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from './routes/routes';
import GlobalStyle from './theme/global';
import { store } from './store';
import { createTheme, ThemeProvider } from '@mui/material';

function App() {

  const THEME = createTheme({
    typography: {
      fontFamily: "Montserrat",
      fontSize: 16,
      fontWeightLight: 300,
      fontWeightMedium: 400,
      fontWeightBold: 600,
    }
  })

  return (
  <ThemeProvider theme={THEME}>
    <ReduxProvider store={store}>  
      <AppRoutes />
      <GlobalStyle />
      <ToastContainer />
    </ReduxProvider>
  </ThemeProvider>
  );
}

export default App;
