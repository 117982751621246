import * as React from 'react';
import { CustomDataGrid, Footer, HeaderBox, WrapperTable } from './styles';
import { Typography } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { ColumnsGroupPrazosContratos } from '../../datasets/DataPrazosContratos';
import { GridColumnGroupingModel } from '@mui/x-data-grid';

export interface IOneTable {
    title: string;
    data: any;
    columns: GridColDef[];
    group?: GridColumnGroupingModel;
}

export const OneTable = (input: IOneTable) => {
  return (
    <WrapperTable>
        <HeaderBox>
          <Typography variant='h5'>
            <strong>{ input.title }</strong>
          </Typography>
        </HeaderBox>
      <CustomDataGrid
        rows={input.data}
        columns={input.columns}
        experimentalFeatures={{ columnGrouping: !!input.group }}
        columnGroupingModel={input.group}
        hideFooter={true}
        rowSelection={false}
      />
    </WrapperTable>
  );
}
