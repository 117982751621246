import React from 'react';

import { CustomTypography } from './styles';

interface ILabelError {
 label: string;
}

const LabelError = ({ label }: ILabelError) => {
  return (
   <CustomTypography>
    { label }
   </CustomTypography>);
}

export default LabelError;