import styled from "styled-components";

export const Container = styled.div`
 display: flex;
 flex: 1;
 flex-direction: row;
 padding: 0 1rem;

  > form {
   display: flex;
   flex: 1;
   flex-direction: row;
   gap: 1rem;
   justify-content: flex-start;
   align-items: center;
  }
`;

export const CustomLabel = styled.label`
 background-color: #F46F62;
 border: 1px solid #F46F62;
 padding: 0.5rem 2rem;
 border-radius: 30px;
 color: #FFF;
 transition: .3s;
 cursor: pointer;

 &:hover {
  border: 1px solid #F46F62;
  background-color: #FFF;
  color: #F46F62
 }
`;

export const CustomFile = styled.input`
 display: none;
`;

