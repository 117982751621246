import { Box, styled } from "@mui/material";

interface IChip {
 active: boolean;
}

export const Container = styled(Box)<IChip>`
 height: 30px;
 width: 30px;
 border-radius: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 background-color: ${ ({ active }) => active ? '#F46F62' : '#EBEBEB' };
 color: ${ ({ active }) =>  active ? '#FFF' : '#000' };

`;