import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, HeaderTable } from './styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import { Typography } from '@mui/material';
import { parseDate } from '../../../AnaliseCarteira/datasets/helpers';

const columns: GridColDef[] = [
  { field: 'periodo', headerName: 'Período', flex: 1 },
  { field: 'vencido_nao_pago', headerName: 'Vencidos Não Pagos', flex: 1 },
]

interface IDataTableSaldoInadimplencia {
 data: Array<any>;
}

const dataLocal = [
  {
    id: 1,
    periodo: "01 A 30 Dias",
    vencido_nao_pago: "R$ 859.562,10",
  }
]

const DataTableSaldoInadimplencia = ({ data }: any) => {
 const navigate = useNavigate();
  const handleSelectItem = (params: any) => {
   // navigate({
   //  pathname: "/file-pos",
   //  search: createSearchParams({
   //   data: params.row,
   //  }).toString()
   // })
  }
  return (
   <WrapperTable>
     <HeaderTable>
        Saldo de Inadimplência
      </HeaderTable>
       <CustomDataGrid
        rows={dataLocal}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        rowSelection={false}
        onRowClick={handleSelectItem}
      />
    </WrapperTable>
  );
}

export default DataTableSaldoInadimplencia;
