import { GridColDef } from '@mui/x-data-grid';
import { parsePct, parseRS, parseInt } from './helpers';

export const ColumnsProducts: GridColDef[] = [
  { field: 'cat', headerName: 'Categoria de Produto', flex: 2, align: 'center', headerAlign: 'center' },
  { field: 'bucket', headerName: 'Carteira', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseRS },
  { field: 'bucket_pct', headerName: 'Carteira %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parsePct },
  { field: 'qty', headerName: '# Contratos', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parseInt },
  { field: 'qty_pct', headerName: '# Contratos %', flex: 1, align: 'center', headerAlign: 'center', valueFormatter: parsePct },
];

export const DataProducts = [
    {
      id: 0,
      cat: 'MCMV',
      qty: 54,
      qty_pct: 0.9,
      bucket: 6777043,
      bucket_pct: 0.924,
    },
    {
        id: 1,
        cat: 'Médio Padrão',
        qty: 3,
        qty_pct: 0.1,
        bucket: 65389,
        bucket_pct: 0.076,
      },
      {
          id: 2,
          cat: 'Total',
          qty: 57,
          qty_pct: 1,
          bucket: 6842432,
          bucket_pct: 1,
        },
  ];
