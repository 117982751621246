import React from 'react';

import { Container } from './styles';
import { Typography } from '@mui/material';

interface IChip {
 active: boolean;
 label: number;
}

const Chip = ({ active, label }: IChip) => {
  return (
   <Container active={active}>
    <Typography>
     {label}
    </Typography>
   </Container>
  );
}

export default Chip;