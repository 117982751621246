import { increaseData } from ".";

const validateInterestRate = (rate) => {
  if (!rate || rate.trim() === '') return false; // aceitável vir nula, significa =zero
 const numberRate = parseInt(rate, 10);
 if (isNaN(numberRate)) return true;
 return numberRate < 0;
}

export function validateFamily3(lines) {
 const erros = { total: 0, legendas: {} };
 lines.forEach((l, n) => {
   if (l.empty) return;
   let chave = 'idvenda_' + l.id_venda
   let validaTaxaJuros = validateInterestRate(l.taxajuros || l.taxajuro)
   if(validaTaxaJuros) increaseData(erros, chave, "Erro_300", (n+2), l.id_venda, 'Taxa de juros com valor inválido');
 })
 return erros;
}
