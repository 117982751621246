import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, HeaderTable } from './styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import { Typography } from '@mui/material';
import { parseDate } from '../../../AnaliseCarteira/datasets/helpers';

const columns: GridColDef[] = [
  { field: 'mes_ref', headerName: 'Mês Ref', flex: 1 },
  { field: 'original', headerName: 'Nome Arquivo', flex: 2 },
  { field: 'status', headerName: 'Status', flex: 1 },
  { field: 'created', headerName: 'Data', flex: 1, valueFormatter: parseDate },
  { field: 'status', headerName: 'Status', flex: 1 },
]

interface IDataTable {
 data: Array<any>;
}

const DataTable = ({ data }: any) => {
 const navigate = useNavigate();
  const handleSelectItem = (params: any) => {
   // navigate({
   //  pathname: "/file-pos",
   //  search: createSearchParams({
   //   data: params.row,
   //  }).toString()
   // })
  }
  return (
   <WrapperTable>
     <HeaderTable>
        Operações
      </HeaderTable>
       <CustomDataGrid
        rows={data?.files}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        rowSelection={false}
        onRowClick={handleSelectItem}
      />
    </WrapperTable>
  );
}

export default DataTable;
