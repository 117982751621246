import * as React from 'react';
import Cookies from 'universal-cookie';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { CustomDrawer, CustomAppBar, BoxLinks, CustomListItemButton, AsideMenuBox, CustomList, CustomListItemText } from './styles';
import { Navigate, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

import { Logo, Icon } from './css';

import logoImg from '../assets/login/direto.svg';
import iconSimulation  from '../assets/layout/icon_simulation.svg';
import iconSimulationSelected  from '../assets/layout/icon_simulation_selected.svg';
import iconOperations from '../assets/layout/icon_operation.svg';
import iconOperationsSelected from '../assets/layout/icon_operation_selected.svg';
import iconConfig  from '../assets/layout/icon_config.svg';
import { Divider } from '@mui/material';
import { useAppSelector } from '../store';
import UserDialog from './UserDialog';

export const Layout = ({children, ...rest}: any) => {
  const cookies = new Cookies();
  const user = useAppSelector(state => state.user);
  const navigate = useNavigate();
  const [selected, setSelected] = React.useState<number>(0);
  let decodedToken:any = null;

  const token = localStorage.getItem('@token');
  const userData: any = localStorage.getItem('@user');
  const parsedUser = JSON.parse(userData);

  if(token){
    decodedToken = jwtDecode(token);
  }

  if(!decodedToken) {
    return <Navigate to="/login" />
  }


  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <CustomAppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Logo
          src={logoImg}
        />
        <BoxLinks>
          <UserDialog username={parsedUser?.name}/>
        </BoxLinks>
      </CustomAppBar>
      <CustomDrawer
        variant="permanent"
      >
        <Toolbar />
        <AsideMenuBox>
          <CustomList>
            <ListItem disablePadding style={{ color: selected === 1 ? '#F46F62' : 'white' }}>
              <CustomListItemButton
                onClick={
                  () => {
                    navigate("/carteira-pre")
                    setSelected(1)
                  }
                }
              >
                  {selected === 1 ? <Icon src={iconSimulationSelected}/> : <Icon src={iconSimulation}/>}
                  <CustomListItemText primary="Analisar Carteira (Pré-Cessão)" />
              </CustomListItemButton>
            </ListItem>
            <ListItem disablePadding style={{ color: selected === 2 ? '#F46F62' : 'white' }}>
              <CustomListItemButton
                onClick={
                  () => {
                    navigate("/operacoes")
                    setSelected(2)
                  }
                }
              >
                {selected === 2 ? <Icon src={iconOperationsSelected}/> : <Icon src={iconOperations}/>}
                <CustomListItemText primary="Operações em Andamento" />
              </CustomListItemButton>
            </ListItem>
          </CustomList>

          <List>
            {parsedUser['custom:admin'] && <ListItem disablePadding style={{ color: selected === 10 ? '#F46F62' : 'white' }}>
              <CustomListItemButton
                onClick={
                  () => {
                    navigate("/configuracao")
                    setSelected(10)
                  }
                }
              >
                {selected === 10 ? <Icon src={iconSimulationSelected}/> : <Icon src={iconSimulation}/>}
                <ListItemText primary="Configurações" />
              </CustomListItemButton>
            </ListItem>}
          </List>
        </AsideMenuBox>
      </CustomDrawer>
      <Box component="main" sx={{ height: "100vh", flex: 1 }}>
        {children}
      </Box>
    </Box>
  );
}
