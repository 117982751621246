import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import { Header, SubHeader } from './stylesMui';
import { Grid } from '@mui/material';
import { useAppSelector } from '../../store';
import { api } from '../../api';
import { DataTable } from './components/Table';
import Card from './components/Card';
import { toast } from 'react-toastify';
import HeaderTitle from '../../components/HeaderTitle';

const Home: React.FC = () => {
  const user = useAppSelector(state => state.user);
  const partners = useAppSelector(state => state.partner);
  const partner = partners.find((part) => part.selected);
  const [portfolios, setPortFolios] = useState<[]>([]);

  const partnerSelected = user.partner?.id;

  const loadFilesPre = () => {
    toast.promise(async() => {
      const response = await api.get(`/backend/partners/${partnerSelected}/portfolios`);
      const portfolios = response.data.data.portfolios.filter((port: any) => port.type === 2);
      setPortFolios(portfolios)
    }, {
      pending: "Carregando portfólios",
      success: "Portfólios carregados",
      error: "Error no carregamento de portfólios"
    })
  }

  useEffect(() => {
    if (partner){
      loadFilesPre();
    } else {
      toast.warning("Nenhum parceiro na carteira selecionado!")
    }
  }, [partner])

  return (
   <Container>
    <HeaderTitle
      title="Análises - Carteira de Recebíveis Imobiliários"
    />
    <SubHeader>
      Aqui você pode acompanhar suas carteiras e análises.
    </SubHeader>
    <Grid container style={{ marginBottom: 16 }}>
      <Grid
        item
        xs={12}
        lg={3}
      >
        <Card />
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
      >
        <Card />
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
      >
        <Card />
      </Grid>
      <Grid
        item
        xs={12}
        lg={3}
      >
        <Card />
      </Grid>
    </Grid>
    <DataTable data={portfolios}/>
   </Container>
  );
}

export default Home;
