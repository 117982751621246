import { Box, Drawer, Grid, Select, Typography, styled, Button } from '@mui/material';

export const Container = styled(Box)``;

export const Content = styled(Grid)`
`;

export const Aside = styled(Grid)`
 display: flex;
 flex-direction: column;
 background-color: #FAFAFA;
 height: 100vh;
 padding: 3rem 1rem;
 gap: 1rem;
`;

export const WrapperContent = styled(Box)`
 display: flex;
 flex-direction: column;
 padding: 3rem 0rem 0rem 3rem;
 max-width: 1400px;
 width: 100%;
`;

export const Title = styled(Typography)`
 font-weight: 300;
 color: #F46F62;
`;

export const FilterBox = styled(Box)`
 display: flex;
 justify-content: space-between;
 align-items: center;
 width: 100%;
 padding: 1rem 1rem;
`;

export const CustomSelect = styled(Select)`
 border-radius: 8px;
 width: 300px;
`;

export const CustomDrawer = styled(Drawer)`
`;

export const WrapperDrawer = styled(Box)`
 display: flex;
 flex-direction: column;
 gap: 1rem;
 padding: 1rem 1rem;
 margin-top: 6rem;
 background-color: #FAFAFA;
 height: 100vh;
`;

export const CustomChipTitle = styled(Button)`
  color: black;
`;
