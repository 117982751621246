import { 
 Container,
} from './styles';
import { MenuItem, Select, Typography } from '@mui/material';
import { CustomSelect, Wrapper } from './stylesMui';
import { Controller, useFormContext } from 'react-hook-form';
import LabelError from '../../shared/LabelError';

interface IData {
  value: string;
  label: string;
}

interface IInputData {
 label: string;
 name: string;
 data: IData[];
 disabled?: boolean;
}

const InputData = ({label, name, data, disabled}: IInputData) => {
  const { control, formState: { errors } } = useFormContext();  
 
  return (
   <Container>
    <Controller
      name={name} 
      control={control}
      defaultValue="None"
      render={
        ({ field }) => (
          <Wrapper>
            <Typography style={{fontSize:'0.9em'}}>{label}</Typography>
            <CustomSelect
              {...field}
              fullWidth
              disabled={disabled}
            >
              {data.map((value) => (
                <MenuItem value={value.value}>{value.label}</MenuItem>
              ))}
            </CustomSelect>
            <LabelError label={errors?.[name]?.message?.toString() ?? ""}/>
          </Wrapper>
        )
      }
    />
   </Container>
  );
}

export default InputData;