import styled from "styled-components";

export const ListDetails = styled.ul`

`;

export const ListDetailItem = styled.li`

`;

export const Form = styled.form`
 display: flex;
 flex-direction: column;
 gap: 1rem;
`;