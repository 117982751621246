import { Box, Button, Typography, styled } from "@mui/material";

export const Container = styled(Box)`
 padding: 7rem 0rem 0rem 3rem;
 max-width: 1600px;
`;

export const ItemPort = styled(Box)`
 display: flex;
 flex: 1;
 padding: 1rem 1rem;
 border-radius: 8px;
 box-shadow: 1px 6px 10px -5px rgba(0,0,0,0.75);
 background-color: #EFEFEF;
 flex-direction: column;
 margin-bottom: 4rem;
`;

export const ItemHeadPort = styled(Box)`
 display: flex;
 flex: 1;
 justify-content: space-between;
 align-items: center;
 margin-bottom: 1rem;
`;

export const PortTitle = styled(Typography)`
 color: #000;
 font-weight: 500;
`;

export const UploadButton = styled(Button)`
 background-color: #F46F62;
 border: 1px solid #F46F62;
 color: #FFF;
 transition: 0.3s;
 padding: .5rem 1rem;
 width: 150px;
 border-radius: 75px;

 &:hover {
  background-color: #FFF;
  border: 1px solid #F46F62;
  color: #F46F62;
 }
`;

export const ModalContainer = styled(Box)`
 position: absolute;
 background-color: #FFF;
 border-radius: 8px;
 width: 1200px;
 height: 250px;
 left: 50%;
 top: 50%;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-direction: column;
 padding: 1rem;
 transform: translate(-50%,-50%);
 `;

 export const HeadModal = styled(Box)`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
 `;