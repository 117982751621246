import moment from 'moment';

const kFormat = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short' // Other option is 'long'
});

export const parseRS = (value?: any) => {
  if (!value || !value.value) return '-';
  const val = parseFloat(value.value);
  return `R$ ${val.toLocaleString('pt-br')}`;
};

export const parseRSK = (value?: any) => {
  if (!value || !value.value) return '-';
  const val = value.value as number;
  return `R$ ${kFormat.format(val)}`;
};

export const parsePct = (value?: any) => {
  if (!value || !value.value) return '-';
  const val = parseFloat(((value.value as number)*100).toFixed(2));
  return `${val.toLocaleString('pt-br')} %`;
};

export const parseInt = (value?: any) => {
  if (!value || !value.value) return '-';
  const val = value.value as number;
  return val.toLocaleString('pt-br');
};

export const parseDate = (value?: any) => {
  if (!value || !value.value) return '-';
  const val = moment(value.value);
  return val.format('DD/MM/YYYY')
};
