import { Box, Select, styled } from "@mui/material";

export const Wrapper = styled(Box)`
 width: 100%;
 padding-top: 0.3rem;
`;

export const CustomSelect = styled(Select)`
height: 2.8125rem;
padding: 0.8rem 0rem;
border-radius: 8px;
font-size: 0.9rem;
background-color: #FFF;
`;