import React, { ReactElement, useState } from 'react';
import { Box } from '@mui/material';
import Accordion  from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAccordionDetails, CustomChip } from './styles';
import { ListDetailItem, ListDetails } from './stylesDefault';
import Chip from './components/Chip';

// import { Container } from './styles';

interface IAccordionMui {
  num: number;
  title: string;
  active: boolean;
  data?: string[];
}

const styles: React.CSSProperties = {
  fontSize: 20
}

const AccordionMui = ({ num, title, active, data }: IAccordionMui) => {
 const [expanded, setExpanded] = useState<boolean>(false)

  return (
   <Box>
    <Accordion expanded={!data ? false : expanded} onChange={() => setExpanded(value => !value)}>
        <AccordionSummary
          expandIcon={!!data && <ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {/* <CustomChip label={num} active={active} />&nbsp; */}
          <Chip active={active} label={num}/>&nbsp;
          <Typography sx={{ marginRight: 5 }} style={styles}>
            {title}
          </Typography>
        </AccordionSummary>
        {!!data &&
          <CustomAccordionDetails>
            {data.length > 0 && <ListDetails>
              {data.map((value) => (
                <ListDetailItem>{value}</ListDetailItem>
              ))}
            </ListDetails>}
          </CustomAccordionDetails>
        }
      </Accordion>
   </Box>
  );
}

export default AccordionMui;
