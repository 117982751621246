import React, { useState } from 'react';
import { Box, Button, Divider, Grid, InputAdornment, TextField } from '@mui/material';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAccordion, CustomAccordionDetails, FilterButton } from './styles';
import { Form } from './stylesDefault';
import { FormProvider, useForm } from 'react-hook-form';
import InputText from '../../../../components/InputText';
import { toast } from 'react-toastify';
import { api } from '../../../../api';
import { useNavigate } from 'react-router-dom';

interface IFiltersAccordionMuiProps {
  fileId: string;
}

const FiltersAccordionMui = ({ fileId }: IFiltersAccordionMuiProps) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      name: null,
      data_maxima_vencimento: null,
      valor_nominal_parcela_minimo: null,
      saldo_devedor_minimo: null,
      saldo_devedor_maximo: null,
      qtd_parcelas_abertas_minima: null,
      aging_maximo: null,
      saldo_minimo_spe: null,
      ltv_maximo: null,
      qtd_parcelas_pagas_minima: null,
    },
  });
  const [expanded, setExpanded] = useState<boolean>(false);
  const defaultState = {
    carregando: false,
    padrao: true,
    salvoId: 0,
    filtrado: false,
    nomeFiltro: null,
  };
  const [state, setState] = useState(defaultState);
  const transientState = Object.assign({}, defaultState);

  const filtrar = async (data: any) => {
    let { padrao, salvoId, ...others } = transientState;
    padrao = Object.values(others).every(x => !x);
    setState({ ...state, ...transientState, padrao, carregando: false, filtrado: !padrao, ...data });
    salvarFiltro(data);
  };

  const salvar = () => {
    if (!transientState.nomeFiltro) return;
    setState({ ...state, nomeFiltro: transientState.nomeFiltro, salvoId: Math.round(Math.random() * 500), padrao: false, filtrado: true });
  };

  const publicar = () => {

  };

  const salvarFiltro = async (data: any) => {
    const toastId = toast.loading('Salvando Filtro');
    try {
      await api.post(`/backend/portfolios/analysis/files/${fileId}/filters`, data);
      navigate(`/status-filtro/${fileId}`);
    } catch (err) {
      toast.error('Falha ao salvar o filtro');
    } finally {
      toast.dismiss(toastId);
      methods.reset();
    }
  };

  return (
    <Box sx={{ marginBottom: expanded ? 3 : 0 }}>
      <CustomAccordion
        expanded={expanded}
        onChange={() => setExpanded(value => !value)}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
        >
          <Typography>Administrador - Filtros</Typography>
        </AccordionSummary>
        <CustomAccordionDetails>
          <Form onSubmit={methods.handleSubmit(filtrar)}>
            <FormProvider {...methods}>
              <Grid container columnSpacing={10}>
                <Grid item xs={12} lg={4}>
                  <InputText name='nome_filtro' type='text' label='Nome Filtro' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='data_maxima_vencimento' type='date' label='Data Máx. Vencimento' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='valor_nominal_parcela_minimo' type='number' label='Valor Mín. Parcela' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='saldo_devedor_minimo' type='number' label='Saldo Mín. Devedor' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='saldo_devedor_maximo' type='number' label='Saldo Máx. Devedor' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='qtd_parcelas_abertas_minima' type='number' label='Qtd. Mín. Parcelas Abertas' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='qtd_parcelas_pagas_minima' type='number' label='Qtd. Mín. Parcelas Pagas' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='aging_maximo' type='number' label='Aging Máx.' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='saldo_minimo_spe' type='number' label='Saldo Mín. SPE' />
                </Grid>

                <Grid item xs={12} lg={4}>
                  <InputText name='ltv_maximo' type='number' label='LTV Máx.' />
                </Grid>
              </Grid>
              <div>
                <FilterButton type='submit' variant='contained' disabled={state.carregando}>{state.carregando ? 'Carregando...' : 'Filtrar'}</FilterButton>
              </div>
            </FormProvider>
          </Form>
        </CustomAccordionDetails>
      </CustomAccordion>
    </Box>
  );
};

export default FiltersAccordionMui;
