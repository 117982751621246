import { increaseData } from ".";

export function validateFamily4(lines) {
 const erros = { total: 0, legendas: {} };
 const V400 = {};
 const V401 = {};
 const V402 = {};
 const V403 = {};
 const V404 = {};
 const V405 = {};
 const V406 = {};
 const V407 = {};
 const V408 = {};
 const V409 = {};
 const V410 = {};
 lines.forEach((l,n) => {
    if (l.empty) return;
    const cod_empresa = l.cdigodaempresa || l.codigodaempresa;
    let chave = 'idvenda_' + l.id_venda;
    let chave2 = 'empresa_' + cod_empresa;
    let chave3 = 'nome_' + l.nomecliente;

    if (!V400[chave]) V400[chave] = { contador: new Set([l.datavenda]), linha: n+2, id_venda: l.id_venda };
    else V400[chave].contador.add(l.datavenda)

    if (!V401[chave]) V401[chave] = { contador: new Set([l.tipovenda]), linha: n+2, id_venda: l.id_venda };
    else V401[chave].contador.add(l.tipovenda)

    if (!V402[chave]) V402[chave] = { contador: new Set([l.valorvenda]), linha: n+2, id_venda: l.id_venda };
    else V402[chave].contador.add(l.valorvenda)

    const cod_obra = l.cdigodaobra || l.codigodaobra;
    if (!V403[chave]) V403[chave] = { contador: new Set([cod_obra]), linha: n+2, id_venda: l.id_venda };
    else V403[chave].contador.add(cod_obra)

    const al_fiduciaria = l.alienaofiduciria || l.alienacaofiduciaria || l.alienacaofiduciria;
    if (!V404[chave]) V404[chave] = { contador: new Set([al_fiduciaria]), linha: n+2, id_venda: l.id_venda };
    else V404[chave].contador.add(al_fiduciaria)

    if (!V405[chave]) V405[chave] = { contador: new Set([l.tipocliente]), linha: n+2, id_venda: l.id_venda };
    else V405[chave].contador.add(l.tipocliente)

    if (!V406[chave]) V406[chave] = { contador: new Set([l.datahabitese]), linha: n+2, id_venda: l.id_venda };
    else V406[chave].contador.add(l.datahabitese)

    if (!V407[chave]) V407[chave] = { contador: new Set([l.categoriaproduto]), linha: n+2, id_venda: l.id_venda };
    else V407[chave].contador.add(l.categoriaproduto)

    if (!V408[chave]) V408[chave] = { contador: new Set([cod_empresa]), linha: n+2, id_venda: l.id_venda };
    else V408[chave].contador.add(cod_empresa)

    if (!V409[chave2]) V409[chave2] = { contador: new Set([l.ufempreendimento]), linha: n+2, id_venda: l.id_venda };
    else V409[chave2].contador.add(l.ufempreendimento)

    if (!V410[chave3]) V410[chave3] = { contador: new Set([l.cpfcnpj]), linha: n+2, id_venda: l.id_venda };
    else V410[chave3].contador.add(l.cpfcnpj)
  })

  Object.keys(V400).forEach(chave => {
    if (V400[chave].contador.size > 1) increaseData(erros, chave, 'Erro_400', V400[chave].linha, V400[chave].id_venda, 'Contrato com Data Venda diferentes')
  })

  Object.keys(V401).forEach(chave => {
    if(V401[chave].contador.size > 1) increaseData(erros, chave, 'Erro_401', V401[chave].linha, V401[chave].id_venda, 'Contrato com mais de um tipo de venda')
  })

  Object.keys(V402).forEach(chave => {
    if(V402[chave].contador.size > 1) increaseData(erros, chave, 'Erro_402', V402[chave].linha, V402[chave].id_venda, 'Contrato com mais de um valor de venda')
  })

  Object.keys(V403).forEach(chave => {
    if(V403[chave].contador.size > 1) increaseData(erros, chave, 'Erro_403', V403[chave].linha, V403[chave].id_venda, 'Contrato com mais de uma obra')
  })

  Object.keys(V404).forEach(chave => {
    if(V404[chave].contador.size > 1) increaseData(erros, chave, 'Erro_404', V404[chave].linha, V404[chave].id_venda, 'Alienação fiduciária não é única por contrato')
  })

  Object.keys(V405).forEach(chave => {
    if(V405[chave].contador.size > 1) increaseData(erros, chave, 'Erro_405', V405[chave].linha, V405[chave].id_venda, 'Contrato com mais de um tipo de cliente')
  })

  Object.keys(V406).forEach(chave => {
    if(V406[chave].contador.size > 1) increaseData(erros, chave, 'Erro_406', V406[chave].linha, V406[chave].id_venda, 'Código de obra com mais de uma data habitese')
  })

  Object.keys(V407).forEach(chave => {
    if(V407[chave].contador.size > 1) increaseData(erros, chave, 'Erro_407', V407[chave].linha, V407[chave].id_venda, 'Contrato com mais de uma categoria de produto')
  })

  Object.keys(V408).forEach(chave => {
    if(V408[chave].contador.size > 1) increaseData(erros, chave, 'Erro_408', V408[chave].linha, V408[chave].id_venda, 'Contrato com mais de uma empresa informada')
  })

  Object.keys(V409).forEach(chave => {
    if(V409[chave].contador.size > 1) increaseData(erros, chave, 'Erro_409', V409[chave].linha, V409[chave].id_venda, 'Empresa com mais de uma UF informada')
  })

  Object.keys(V410).forEach(chave => {
    if(V410[chave].contador.size > 1) increaseData(erros, chave, 'Erro_410', V410[chave].linha, V410[chave].id_venda, 'Mais de um nome para o mesmo CPF/CNPJ')
  })

 return erros;
}
