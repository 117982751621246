/* eslint-disable no-template-curly-in-string */
import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Container } from './styles';
import { IGraph } from '../../datasetInterface';

interface IInput {
  data: IGraph
}

const ChartNPLHistory = (input: IInput) => {
 const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    title: {
      text: 'Histórico de Provisionamento'
    },
    xAxis: {
      crosshair: true,
      accessibility: {
        description: 'Mês/Ano'
      },
      categories: input.data.x,
    },
    yAxis: {
      min: 0,
      title: {
        text: "% Provisionamento"
      },
    },
    series: [{
      type: 'column',
      name: "% Provisionamento",
      data: input.data.y.map(y => Math.round(y*100)/100),
      color: "#F46F62"
    }]
  };
  return (
   <Container>
     <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
   </Container>
  );
}

export default ChartNPLHistory;
