import React from 'react';

import { Container } from './styles';
import HeaderTitle from '../../components/HeaderTitle';
import SubHeader from '../../components/SubHeader';
import Links from './Links';

const Configuracao = () => {
  return (
    <Container>
      <HeaderTitle 
        title='Configurações'
        variant='h3'
      />
      <SubHeader 
        title='Gerencie sua aplicação a parti deste painel'
        style={{
          marginBottom: 32
        }}
      />
      <Links 
        label='Parceiros'
        to={"/parceiros"}
      />
      <Links 
        label='Analise pos venda'
        to={"/analise-pos-venda"}
      />
    </Container>
  );
}

export default Configuracao;