import { Card, Typography, styled } from "@mui/material";

export const CustomCard = styled(Card)`
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 align-items: flex-start;
 background-color: #000;
 color: #FFF;
 width: 250px;
 border: 1px solid #000;
 padding-left: 1rem;
 box-shadow: 0px 6px 4px -3px rgba(0,0,0,0.43);
 border-radius: 8px;
 margin-bottom: 4rem;

 &:hover {
  border: 1px solid #F46F62;
  background-color: #F46F62;
  color: #000;
  transition: background-color 0.3s, border 0.3s, color 0.3s;
 }
`;

export const Title = styled(Typography)`
 
`;

export const SubTitle = styled(Typography)`
 font-weight: 600;
`;