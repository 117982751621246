/* eslint-disable no-template-curly-in-string */
import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Container } from './styles';
import { IGraph } from '../../datasetInterface';

interface IInput {
  data: IGraph
}

const ChartContracts = (input: IInput) => {
 const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    title: {
      text: ''
    },
    xAxis: {
      crosshair: true,
      accessibility: {
        description: 'Contratos'
      },
      categories: input.data.x,
    },
    yAxis: {
      min: 0,
      title: {
        text: "# de Contratos"
      },
    },
    series: [{
      type: 'column',
      name: "Valor dos Contratos",
      data: input.data.y,
      color: "#F46F62"
    }]
  };
  return (
   <Container>
     <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
    />
   </Container>
  );
}

export default ChartContracts;
