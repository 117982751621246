import { Box, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";


export const Header = styled(Typography)`
 font-weight: 300;
 margin-bottom: 2rem;
    font-size: 2rem;
`;

export const SubHeader = styled(Typography)`
 margin-bottom: 4rem;
`;

export const HeaderTable = styled(Box)`
  background-color: #FAFAFA;
  border-radius: 8px;

  box-shadow: 0px 0px 10px -3px rgba(0,0,0,0.75);
`;

export const HeaderTableTitle = styled(Typography)`
  padding: 1rem;
  font-weight: 500;
`;

export const CustomDataGrid = styled(DataGrid)`
  background-color: #FAFAFA;

.MuiDataGrid-main > .MuiDataGrid-withBorderColor {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.MuiDataGrid-columnHeaderTitle {
 font-weight: 600;
}

border: none;
`;
